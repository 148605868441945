import {WebSocketChannelInfo} from './web-socket-channel-info.model';
import {RxWebsocketSubject} from './../../_services/rxWebsocketSubject';
import {Serializable} from './../../_services/deserialize.service';
import {Observable, BehaviorSubject} from 'rxjs';
import {WebSocketChannelUser} from './webSocketChannelUser.model';

export class WebSocketChannel implements Serializable<WebSocketChannel> {
    activeWS: RxWebsocketSubject<any>;
    activeWSSubscription: Observable<any>;
    channelData: WebSocketChannelUser;
    channelData$: BehaviorSubject<WebSocketChannelUser>;
    remoteClients: WebSocketChannelUser;
    patientReady: BehaviorSubject<{ status: boolean; description: string }>;
    channelInfo: WebSocketChannelInfo;
    channelInfo$: BehaviorSubject<WebSocketChannelInfo>;
    remoteClient: WebSocketChannelUser;
    remoteClient$: BehaviorSubject<WebSocketChannelUser>;
    chatData$: BehaviorSubject<any>;

    deserialize(input) {
        this.activeWS = input.activeWS;
        this.activeWSSubscription = input.activeWSSubscription;
        this.channelData = input.channelData;
        this.channelData$ = input.channelData$;
        this.remoteClients = input.remoteClients;
        this.patientReady = input.remoteReady;
        this.channelInfo = input.channelInfo;
        this.channelInfo$ = input.channelInfo$;
        this.remoteClient = input.remoteClient;
        this.remoteClient$ = input.remoteClient$;
        this.chatData$ = input.chatData$;
        return this;
    }
}
