import * as moment from 'moment';
import { Serializable } from '../deserialize.interface';

export class Slot implements Serializable<Slot> {
    start: moment.Moment;
    end: moment.Moment;
    assetId: number;
    deserialize(input) {
        this.start = input.start;
        this.end = input.end;
        this.assetId = input.assetId;

        return this;
    }
}
