import { Serializable } from '../../_services/deserialize.service';

export class WebSocketChannelUser implements Serializable<WebSocketChannelUser> {
    //eventType: WebSocketEventType;
    //iterofiles: number;
    userName: string;
    userId: string;
    //displayName: string;
    userType: 'doctor' | 'patient';

    onTerm: boolean;
    videoModalOpened: boolean;
    cameraReady: boolean;
    cameraError: boolean;
    cameraMuted: boolean;
    microphoneReady: boolean;
    microphoneError: boolean;
    microphoneMuted: boolean;
    playoutMuted: boolean;
    clientReady: boolean;
    clientJoined: boolean;
    streamPublished: boolean;
    inCall: boolean;
    uid: number;

    timestamp: string;

    peer: any;

    deserialize(input) {
        this.userName = input.userName;
        this.userId = input.userId;
        //this.displayName = input.displayName;
        this.userType = input.userType === 'Doctor' || input.userType === 'doctor' ? 'doctor' : 'patient';

        this.onTerm = input.onTerm ? input.onTerm : false;
        this.videoModalOpened = input.videoModalOpened ? input.videoModalOpened : false;
        this.cameraReady = input.cameraReady ? input.cameraReady : false;
        this.cameraError = input.cameraError ? input.cameraError : false;
        this.cameraMuted = input.cameraMuted ? input.cameraMuted : false;
        this.microphoneReady = input.microphoneReady ? input.microphoneReady : false;
        this.microphoneError = input.microphoneError ? input.microphoneError : false;
        this.microphoneMuted = input.microphoneMuted ? input.microphoneMuted : false;
        this.playoutMuted = input.playoutMuted ? input.playoutMuted : false;
        this.clientReady = input.clientReady ? input.clientReady : false;
        this.clientJoined = input.clientJoined ? input.clientJoined : false;
        this.streamPublished = input.streamPublished ? input.streamPublished : false;
        this.inCall = input.inCall ? input.inCall : false;
        this.uid = input.uid ? input.uid : null;

        this.timestamp = input.timestamp ? input.timestamp : new Date();
        this.peer = input.peer;
        return this;
    }
}
