<div
    class="row telemedicine-large-modal"
    [ngStyle]="{
        'max-width': !checkedPrivacyPolicy ? '600px' : 'auto',
        'min-width': !checkedPrivacyPolicy ? '30vw' : 'auto'
    }"
>
    <div class="col-12 p-0">
        <!--        <h1 class="centered w-100 p-3 title m-0 m&#45;&#45;font-boldest m&#45;&#45;font-light">ePosvet</h1>-->
        <!--        uid: {{this.uid}}-->
        <!--        <div class="m-portlet mb-0 patientCard" *ngIf="userType === 'Doctor'">-->
        <!--            <telemedicine-patient-info [setData]="data"></telemedicine-patient-info>-->
        <!--        </div>-->
        <!--        <div class="alert alert-warning m&#45;&#45;font-inverse-warning m&#45;&#45;icon-font-size-sm2 text-center" role="alert">-->
        <!--            Pridružili se boste video posvetu, zato boste delili zajem kamere in mikrofona na vaši napravi-->
        <!--        </div>-->
        <article class="call shadow p-0 m-0" #videoModal>
            <div class="col m-0 px-2 pt-2">
                <div class="video-container">
                    <div
                        class="remote-info"
                        *ngIf="callState === callStates.CALL_STARTED && channelData?.userType === 'doctor'"
                    >
                        <div class="text-danger" *ngIf="remoteClient?.cameraError">
                            <i class="la la-video-camera"></i> Pacient nima kamere ali je onemogočil dostop
                        </div>
                        <div class="text-danger" *ngIf="remoteClient?.microphoneError">
                            <i class="la la-microphone"></i> Pacient nima mikrofona ali je onemogočil dostop
                        </div>
                        <div class="text-warning" *ngIf="remoteClient?.cameraMuted && !remoteClient?.cameraError">
                            <i class="la la-video-camera"></i> Pacient je izključil kamero
                        </div>
                        <div
                            class="text-warning"
                            *ngIf="remoteClient?.microphoneMuted && !remoteClient?.microphoneError"
                        >
                            <i class="la la-microphone"></i> Pacient je izključil mikrofon
                        </div>
                        <div class="text-warning" *ngIf="remoteClient?.playoutMuted">
                            <i class="la la-volume-off"></i> Pacient je izključil zvočnike/slušalke
                        </div>
                    </div>
                    <div class="video-tools m--hidden-tablet-and-mobile">
                        <button
                            class="m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-primary m-btn--icon m-btn--icon-only m-btn--pill"
                            (click)="toggleFullScreen(videoModal)"
                            [ngbTooltip]="'Vklop/Izklop celozaslonskega načina'"
                            *ngIf="callState === callStates.CALL_STARTED"
                        >
                            <i class="la la-expand"></i>
                        </button>
                    </div>
                    <div
                        class="remote_calls local"
                        id="agora-remote-video"
                        #remoteVideo
                        [ngClass]="{ settingsOverlay: settingsToggled }"
                    >
                        <div
                            class="alert alert-danger connecting"
                            *ngIf="!remoteClient?.clientReady && callState === callStates.CALL_STARTED"
                        >
                            {{ channelData?.userType === 'patient' ? 'Izvajalec ' : 'Pacient ' }} je zaključil klic ali
                            izgubil povezavo
                        </div>
                        <div
                            class="alert alert-link connecting"
                            *ngIf="
                                callState === callStates.CALL_STARTED &&
                                !channelData?.streamPublished &&
                                !(channelData?.cameraError && channelData?.microphoneError)
                            "
                        >
                            Povezujem...
                        </div>
                        <div
                            class="alert alert-danger connecting"
                            *ngIf="
                                callState === callStates.CALL_STARTED &&
                                remoteClient?.cameraError &&
                                remoteClient?.clientReady &&
                                channelData?.userType === 'patient'
                            "
                        >
                            Izvajalec nima vključene kamere
                        </div>
                    </div>
                    <div
                        class="local-video-container"
                        [ngClass]="{
                            'call-started': callState === callStates.CALL_STARTED && !settingsToggled,
                            'call-ended': callState === callStates.CALL_ENDING && !settingsToggled
                        }"
                    >
                        <ng-container *ngIf="!this.checkedPrivacyPolicy; else elseTelemedicine">
                            <ng-container>
                                <h4 class="text-center m--font-boldest mb-3">
                                    Politika zasebnosti ponudnika storitve
                                </h4>
                                <p class="text-center pp">
                                    <input
                                        type="checkbox"
                                        class="m-checkbox mr-2 mb-0"
                                        (change)="togglePrivacyPolicy()"
                                    />
                                    <span class="privacyPolicy">
                                        Izjavljam, da sem pred uporabo storitve seznanjen/a s
                                        <a
                                            class="alert-link m-font-boldest no-wrap"
                                            href="{{ privacyPolicyUrl }}"
                                            target="_blank"
                                            rel="noopener"
                                            >Politiko zasebnosti</a
                                        >
                                        in se z njo strinjam.</span
                                    >
                                </p>
                                <div
                                    *ngIf="this.readPrivacyPolicy"
                                    class="btn m-btn--pill btn-primary font-weight-bold p-3 d-block mt-3"
                                    (click)="continue()"
                                >
                                    NADALJUJ
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #elseTelemedicine>
                            <div
                                *ngIf="
                                    remoteClient &&
                                    remoteClient.inCall &&
                                    callState === callStates.DEVICE_TEST &&
                                    !outsideCallInProgress
                                "
                                class="alert alert-success m-0"
                            >
                                <ng-container *ngIf="channelData?.userType === 'patient'">
                                    Zdravnik je v klicu.
                                </ng-container>
                                <ng-container *ngIf="channelData?.userType === 'doctor'">
                                    Pacient je v klicu.
                                </ng-container>
                            </div>
                            <ng-container *ngIf="callState === callStates.CALL_STARTING && !outsideCallInProgress">
                                <ng-container *ngIf="channelData?.userType === 'patient'">
                                    <div *ngIf="!remoteClient" class="alert alert-warning m-0">
                                        Začeli ste {{ 'ePosvet' | tmAppName }}. Čakate na izvajalca.<br />
                                    </div>

                                    <div *ngIf="remoteClient?.onTerm" class="alert alert-success m-0">
                                        Začeli ste {{ 'ePosvet' | tmAppName }}. Izvajalec se pripravlja na klic.
                                    </div>
                                </ng-container>
                                <div *ngIf="channelData?.userType === 'doctor'" class="alert alert-success m-0">
                                    Začeli ste {{ 'ePosvet' | tmAppName }}. Čakate na pacienta.
                                </div>
                            </ng-container>
                            <div class="alert alert-danger" *ngIf="outsideCallInProgress">
                                Klic je že aktiven.
                            </div>
                            <div class="alert alert-danger" *ngIf="!remoteClient?.clientReady && settingsToggled">
                                {{ channelData?.userType === 'patient' ? 'Izvajalec ' : 'Pacient ' }} je zaključil klic
                                ali izgubil povezavo
                            </div>
                            <div class="text-center" *ngIf="callState === callStates.CALL_ENDING">
                                <h5 class="font-weight-bold mb-4">Zapustili ste video</h5>
                                <p>Klic lahko ponovno vzpostavite</p>
                                <i class="la la-arrow-down"></i>
                            </div>
                            <div
                                id="agora-local-video"
                                [ngClass]="{
                                    loaded: localAvailable.final === localAvalaibleEnum.READY,
                                    'video-error': channelData?.cameraError
                                }"
                                [ngSwitch]="localAvailable.final"
                            >
                                <ng-container *ngSwitchCase="localAvalaibleEnum.READY"></ng-container>
                                <div class="alert alert-info" *ngSwitchCase="localAvalaibleEnum.WAITING">
                                    {{ localAvailable.final }}
                                </div>
                                <div class="alert alert-warning" *ngSwitchDefault>
                                    {{ localAvailable.final }}
                                </div>
                                <div
                                    *ngIf="channelData?.cameraError && !channelData?.microphoneError"
                                    class="alert alert-danger"
                                >
                                    {{ localAvailable.initial }}
                                </div>
                            </div>
                            <div class="device-settings" *ngIf="callState < callStates.CALL_STARTED || settingsToggled">
                                <div class="video-settings">
                                    <div><i class="la la-video-camera"></i></div>
                                    <div>
                                        <span class="m-switch m-switch--outline m-switch--success m-switch--icon">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [checked]="!channelData?.cameraMuted"
                                                    (change)="toggleVideo()"
                                                    [disabled]="
                                                        localAvailable.final !== localAvalaibleEnum.READY ||
                                                        channelData?.cameraError
                                                    "
                                                />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="device-state">
                                        <span
                                            class="text-danger"
                                            *ngIf="channelData?.cameraError"
                                            [ngbTooltip]="localAvalaibleEnum[localAvailable.initial]"
                                        >
                                            Napaka!
                                        </span>
                                        <span
                                            class="text-danger"
                                            *ngIf="channelData?.cameraMuted && !channelData?.cameraError"
                                            >Neaktivno</span
                                        >
                                        <span
                                            class="text-success"
                                            *ngIf="!channelData?.cameraMuted && !channelData?.cameraError"
                                            >Aktivno</span
                                        >
                                    </div>
                                    <div>
                                        <div ngbDropdown class="d-inline-block">
                                            <button
                                                [disabled]="channelData?.cameraError"
                                                class="btn btn-outline-primary"
                                                id="videoDevices"
                                                ngbDropdownToggle
                                            >
                                                <span *ngIf="activeVideoDevice">{{ activeVideoDevice }}&nbsp;</span>
                                                <span *ngIf="!activeVideoDevice">
                                                    {{ videoDevices[0]?.label }}&nbsp;
                                                </span>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <button
                                                    *ngFor="let camera of videoDevices"
                                                    (click)="switchCamera(camera)"
                                                    [value]="camera?.label"
                                                    ngbDropdownItem
                                                >
                                                    {{ camera?.label }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="audio-settings">
                                    <div><i class="la la-microphone"></i></div>
                                    <div>
                                        <span class="m-switch m-switch--outline m-switch--success m-switch--icon">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    [checked]="!channelData?.microphoneMuted"
                                                    (change)="toggleAudio()"
                                                    [disabled]="
                                                        localAvailable.final !== localAvalaibleEnum.READY ||
                                                        channelData?.microphoneError
                                                    "
                                                />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="device-state">
                                        <span
                                            class="text-danger"
                                            *ngIf="channelData?.microphoneError"
                                            [ngbTooltip]="localAvalaibleEnum[localAvailable.initial]"
                                        >
                                            Napaka!
                                        </span>
                                        <span
                                            class="text-danger"
                                            *ngIf="channelData?.microphoneMuted && !channelData?.microphoneError"
                                            >Neaktivno</span
                                        >
                                        <span
                                            class="text-success"
                                            *ngIf="!channelData?.microphoneMuted && !channelData?.microphoneError"
                                            >Aktivno</span
                                        >
                                    </div>
                                    <div>
                                        <div ngbDropdown class="d-inline-block">
                                            <button
                                                [disabled]="channelData?.microphoneError"
                                                class="btn btn-outline-primary"
                                                id="audioDevices"
                                                ngbDropdownToggle
                                            >
                                                <span *ngIf="activeAudioDevice">{{ activeAudioDevice }}&nbsp;</span>
                                                <span *ngIf="!activeAudioDevice">
                                                    {{ audioDevices[0]?.label }}&nbsp;
                                                </span>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <button
                                                    *ngFor="let audio of audioDevices"
                                                    (click)="switchAudio(audio)"
                                                    [value]="audio?.label"
                                                    ngbDropdownItem
                                                >
                                                    {{ audio?.label }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="playout-settings">
                                    <div><i class="la la-volume-up"></i></div>
                                    <div>
                                        <span class="m-switch m-switch--outline m-switch--success m-switch--icon">
                                            <label>
                                                <!--                                            <input-->
                                                <!--                                                type="checkbox"-->
                                                <!--                                                [checked]="audioVolume > 0 && playoutDevices.length > 0"-->
                                                <!--                                                (change)="toggleAudioVolume()"-->
                                                <!--                                                [disabled]="localAvalaible !== 1"-->
                                                <!--                                            />-->
                                                <input
                                                    type="checkbox"
                                                    [checked]="this.telemedAgoraService.audioVolume > 0"
                                                    (change)="toggleAudioVolume()"
                                                    [disabled]="localAvailable.final !== localAvalaibleEnum.READY"
                                                />
                                                <span></span>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="device-state">
                                        <span class="text-danger" *ngIf="this.telemedAgoraService.audioVolume === 0"
                                            >Neaktivno</span
                                        >
                                        <span class="text-success" *ngIf="this.telemedAgoraService.audioVolume > 0"
                                            >Aktivno</span
                                        >
                                    </div>
                                    <div>
                                        <div ngbDropdown class="d-inline-block">
                                            <button
                                                class="btn btn-outline-primary"
                                                ngbDropdownToggle
                                                [disabled]="!activePlayoutDevice"
                                            >
                                                <span
                                                    >{{
                                                        activePlayoutDevice?.label
                                                            ? activePlayoutDevice?.label
                                                            : 'Zvočniki'
                                                    }}&nbsp;</span
                                                >
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <button
                                                    *ngFor="let playout of playoutDevices; let i = index"
                                                    (click)="switchPlayoutDevice(playout)"
                                                    [value]="playout?.label"
                                                    ngbDropdownItem
                                                >
                                                    {{ playout?.label ? playout?.label : 'Zvočniki ' + (i + 1) }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div
                [ngClass]="{
                    'call-init': callState === callStates.DEVICE_TEST && !settingsToggled,
                    'call-started': callState === callStates.CALL_STARTED,
                    'call-starting': callState === callStates.CALL_STARTING && !settingsToggled,
                    'call-ended': callState === callStates.CALL_ENDING && !settingsToggled
                }"
                class="col-12 call col"
            >
                <div class="row h-100">
                    <div class="col-12">
                        <div class="video-buttons">
                            <button
                                *ngIf="callState === callStates.CALL_STARTED"
                                class="btn btn-circle"
                                [ngClass]="!channelData?.microphoneMuted ? 'btn-metal' : 'btn-danger'"
                                (click)="toggleAudio()"
                                [disabled]="channelData?.microphoneError"
                            >
                                <i class="la la-microphone" *ngIf="!channelData?.microphoneMuted"></i>
                                <i class="la la-microphone-slash" *ngIf="channelData?.microphoneMuted"></i>
                            </button>
                            <button
                                *ngIf="callState === callStates.CALL_STARTED"
                                class="btn btn-circle"
                                [ngClass]="!channelData?.cameraMuted ? 'btn-metal' : 'btn-danger'"
                                (click)="toggleVideo()"
                                [disabled]="channelData?.cameraError"
                            >
                                <i class="la la-video" *ngIf="!channelData?.cameraMuted"></i>
                                <i class="la la-video-slash" *ngIf="channelData?.cameraMuted"></i>
                            </button>
                            <button
                                *ngIf="channelData && channelData?.clientReady"
                                class="btn btn-circle btn-circle-lg btn-danger font-weight-bold"
                                (click)="endCall()"
                            >
                                <i class="la la-phone m--icon-font-size-lg2"></i>
                            </button>
                            <div
                                class="d-inline-block"
                                [ngbTooltip]="
                                    (!enableTelemedicine && !is15minBefore
                                        ? 'Začetek je mogoč 15 minut pred začetkom termina.'
                                        : '') || (!enableTelemedicine && !isMax1hourAfter ? 'Klic je zaključen.' : '')
                                "
                            >
                                <button
                                    *ngIf="!channelData?.clientReady && checkedPrivacyPolicy"
                                    [disabled]="
                                        !enableTelemedicine ||
                                        localAvailable.final === localAvalaibleEnum.WAITING ||
                                        outsideCallInProgress ||
                                        !checkedPrivacyPolicy
                                    "
                                    class="btn m-btn--pill btn-primary font-weight-bold p-3"
                                    (click)="startCall()"
                                >
                                    <i class="la la-phone mr-2"></i>
                                    <ng-container *ngIf="callState !== callStates.CALL_ENDING"
                                        >Začnite {{ 'ePosvet' | tmAppName }}</ng-container
                                    >
                                    <ng-container *ngIf="callState === callStates.CALL_ENDING"
                                        >Nadaljujte {{ 'ePosvet' | tmAppName }}</ng-container
                                    >
                                </button>
                            </div>
                            <button
                                *ngIf="callState === callStates.CALL_STARTED"
                                class="btn btn-circle font-weight-bold"
                                [ngClass]="{
                                    'btn-metal': this.telemedAgoraService.audioVolume > 0,
                                    'btn-danger': this.telemedAgoraService.audioVolume === 0
                                }"
                                (click)="setAudioVolume()"
                            >
                                <i class="la la-volume-up" *ngIf="this.telemedAgoraService.audioVolume >= 75"></i>
                                <i
                                    class="la la-volume-down"
                                    *ngIf="
                                        this.telemedAgoraService.audioVolume > 0 &&
                                        this.telemedAgoraService.audioVolume < 75
                                    "
                                ></i>
                                <i class="la la-volume-off" *ngIf="this.telemedAgoraService.audioVolume === 0"></i>
                            </button>
                            <button
                                *ngIf="callState === callStates.CALL_STARTED"
                                [ngClass]="settingsToggled ? 'btn-primary' : 'btn-metal'"
                                class="btn btn-circle"
                                (click)="toggleSettings()"
                            >
                                <i class="la la-cog"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</div>
