<!-- begin::Page loader -->
<div class="m-page-loader m-page-loader--base m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
    <div class="m-blockui">
        <span i18n="@@a4e4f2c2-3666-599e-a233-e3c8975992f6">
            Prosimo počakajte...
        </span>
        <span>
            <div class="m-loader m-loader--brand"></div>
        </span>
    </div>
</div>
<!-- end::Page loader -->
<!-- begin::Page loader -->
<!--<div class="m-page-loader m-page-loader&#45;&#45;base">-->
<!--    <div class="m-blockui">-->
<!--        <span i18n="@@e6cfffd2-a839-570c-b64c-861cd4129e76">-->
<!--            Prosimo počakajte...-->
<!--        </span>-->
<!--        <span>-->
<!--            <div class="m-loader m-loader&#45;&#45;brand"></div>-->
<!--        </span>-->
<!--    </div>-->
<!--</div>-->
<div class="content-wrapper mf-content-wrapper h-100">
    <div [@routeAnimations]="prepareRoute(outlet)" class="m-container h-100">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
<app-footer [guidData]="cData"></app-footer>

<!-- end:: Page -->
<!--<app-scroll-top></app-scroll-top>-->
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->
<!-- begin::Page Loader -->
<!--<script>-->
<!--    $(window).on('load', function() {-->
<!--        $('body').removeClass('m-page&#45;&#45;loading');-->
<!--    });-->
<!--</script>-->
<!-- end::Page Loader -->
