export const environment = {
    VERSION: require('../../package.json').version,
    production: true,
    RELEASE_STAGE: 'production',

    service: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        apiPort: '',
        apiUrl: '/patient/api',
        erpApiUrl: '/erp/api',
        communicationapi: '/erp/communicationapi',
    },
    patient: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si',
        port: '',
        url: '/booking/patient/api',
    },
    telemed: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/telemed',
    },
    ePosvet: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/eposvet',
    },
    agora: {
        appId: '74694d6ab72646fea80f43f17aab66b6',
        logLevel: 2,
        proxyServer: 'eposvet-proxy.eambulanta.si',
        turnServer: {
            turnServerURL: '18.184.90.216',
            username: 'medifit',
            password: '123krneki567',
            udpport: '3478',
            tcpport: '3478',
            forceturn: false,
        },
    },
    webSocketTelemed: {
        protocol: 'wss://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/ws/telemed/',
    },
    document: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/docapi',
    },
    printserver: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/papi',
    },
};
