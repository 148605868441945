import { ReservationConfirmationRequest } from './../_model/reservations/reservation-confirmation-request.model';
import { MaskedPrereservationRequest } from './../_model/reservations/masked-prereservation-request.model';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ReservationsService {
    constructor(private http: HttpClient, private httpClient: HttpClientService, private helper: HelperService) {}

    getTokenInfo(token: string) {
        return this.http.get(this.getReservationsUlr('booking/info/' + token));
    }

    public getFreeSlotsWidget(
        apiKey: string,
        subcKey: string,
        serviceKey: string,
        from: moment.Moment,
        to: moment.Moment,
    ): Observable<any> {
        const fromS: string = from.format('YYYY-MM-DD');
        const toS: string = to.format('YYYY-MM-DD');
        return this.httpClient.get(
            this.helper.getPatientUrl(
                `freeslots/contractors/${apiKey}/subcontractors/${subcKey}/services/${serviceKey}?from=${fromS}&to=${toS}`,
            ),
        );
    }

    public getContractorServiceInfo(apiKey: string, serviceKey: string): Observable<any> {
        return this.httpClient.get(this.helper.getPatientUrl(`contractors/${apiKey}/services/${serviceKey}`));
    }

    // http://localhost:9090/booking/patient/api/freeslots/contractors/5ORvlBVKRPGppAMOoKtqme4/services/lJQIvvaLMPlWe35GggTbU6o?from=2020-04-11&to=2020-04-20

    public getFreeSlots(
        token: string,
        maskedContractorId: string,
        subcontractor: number,
        service: number,
        from: Date | moment.MomentInput,
        to: Date | moment.MomentInput,
    ) {
        const params = {
            maskedContractorId: maskedContractorId,
            contractor: {
                subcontractors: [
                    {
                        id: subcontractor,
                    },
                ],
            },
            service: {
                id: service,
            },
            from: moment(from).format('YYYY-MM-DD'),
            to: moment(to).format('YYYY-MM-DD'),
        };

        return this.http.post(this.getReservationsUlr('freeslots/' + token), params);
    }

    // public getFreeSlotsWidget(data: MaskedPrereservationRequest): Observable<any> {
    //     const fromS: string = data.from.format('YYYY-MM-DD');
    //     const toS: string = data.to.format('YYYY-MM-DD');
    //     return this.httpClient.get(
    //         this.helper.getPatientUrl(
    //             `freeslots/contractors/${data.maskedContractorId}/subcontractors/${data.maskedSubcontractorId}/services/${data.maskedServiceId}?from=${fromS}&to=${toS}`,
    //         ),
    //     );
    // }

    public createMaskedPrereservation(data: MaskedPrereservationRequest): Observable<ReservationConfirmationRequest> {
        return this.httpClient.post(this.getReservationsUlr('prereservation'), data);
    }

    public confirmCustomerPrereservation(prereservationid: string): Observable<ReservationConfirmationRequest> {
        return this.httpClient.post(this.getReservationsUlr(`reservations/${prereservationid}/confirm`));
    }

    //     @PostMapping("/reservations/{prereservationid}/confirm")
    // public void confirmPatientReservation(@PathVariable String prereservationid) {

    public createPrereservation(
        token: string,
        maskedContractorId: string,
        subcontractor: number,
        service: number,
        customer: number,
        from: Date | moment.MomentInput,
        to: Date | moment.MomentInput,
    ) {
        const params = {
            maskedContractorId: maskedContractorId,
            contractor: {
                subcontractors: [
                    {
                        id: subcontractor,
                    },
                ],
            },
            service: {
                id: service,
            },
            customer: {
                id: customer,
            },
            from: moment(from).toISOString(),
            to: moment(to).toISOString(),
        };

        return this.http.post(this.getReservationsUlr('prereservation/' + token), params);
    }

    public deletePrereservation(aggregateId: number, prereservationId: number) {
        return this.http.delete(this.getErpUrl('prereservation/' + aggregateId + '/' + prereservationId));
    }

    public widgetConfirmPrereservation(data: ReservationConfirmationRequest): Observable<any> {
        return this.httpClient.post(this.getReservationsUlr('reservation') + '/', data);
    }

    public confirmPrereservation(token: string, aggregateId: number, prereservationId: number) {
        const params = {
            aggregateId: aggregateId,
            prereservationId: prereservationId,
        };

        return this.http.post(this.getReservationsUlr('reservation/' + token), params);
    }

    public getReservationsUlr(path?: string) {
        if (!path) {
            path = '';
        }
        return (
            environment.service.protocol +
            environment.service.rootUrl +
            environment.service.apiPort +
            environment.service.apiUrl +
            '/' +
            path
        );
    }

    public getErpUrl(path?: string) {
        if (!path) {
            path = '';
        }
        return (
            environment.service.protocol +
            environment.service.rootUrl +
            environment.service.apiPort +
            environment.service.erpApiUrl +
            '/' +
            path
        );
    }
}
