import { StorageService } from './storage.service';
import { Stream } from 'ngx-agora';
import { WsCallStateType } from './../_enums/ws-call-state-type.enum';
import { WebSocketChannelInfo } from './../_model/telemedicine/web-socket-channel-info.model';
import { map, tap } from 'rxjs/operators';
import { RxWebsocketSubject } from './rxWebsocketSubject';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, interval, Observable, Observer, of, Subject } from 'rxjs';
import { TelemedicineService } from './telemedicine.service';
import { WebSocketChannelUser } from '../_model/telemedicine/webSocketChannelUser.model';
import { CaseService } from './case.service';
import { WsMessage } from '../_model/telemedicine/ws-message.model';
import { WsMessageType } from '../_enums/ws-message-type.enum';
import { WebSocketChannel } from '../_model/telemedicine/web-socket-channel.model';
import { DocumentData } from '../_model/common/document-data.model';

@Injectable({
    providedIn: 'root',
})
export class WebsocketClientService {
    private channels: WebSocketChannel[] = [];
    private userType: string = 'patient';

    private connectionStatus$: BehaviorSubject<any> = new BehaviorSubject(false);

    public outsideCallInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private callState$: BehaviorSubject<WsCallStateType> = new BehaviorSubject(WsCallStateType.NONE);
    private callState: WsCallStateType = WsCallStateType.NONE;

    $callTimer: Observable<number>;
    callTotalTime;
    $callTotalTime: any = sessionStorage.getItem('callTotalTime') ? Number(sessionStorage.getItem('callTotalTime')) : 0;
    timer: any;

    private initWebSocketResolver$: Subject<any> = new Subject();

    constructor(
        private storage: StorageService,
        private telemedRest: TelemedicineService,
        private caseService: CaseService,
    ) {}

    public initWebSocket(channelId: string): Observable<any> {
        if (channelId) {
            this.telemedRest.getReservationInfo(channelId).subscribe((response) => {
                const userData = this.parseUserData(response);
                //this.storage.setUserId(response.uid);
                //const userWSid = getSocketUserId(userId);
                //this.storage.setAgoraToken(response.token);
                //this.storage.setChannelUserId(userWSid);

                this.initSocketChannel(channelId, userData);
                this.startSocketConnection(channelId);
                //this.isLoaded = true;
            });
        }
        return this.initWebSocketResolver$;
    }

    public initSocketChannel(channelId: string, userData: WebSocketChannelUser) {
        if (!this.channels[channelId]) {
            this.channels[channelId] = new WebSocketChannel().deserialize({
                activeWS: null,
                activeWSSubscription: null,
                channelInfo: new WebSocketChannelInfo().deserialize({}),
                channelData: userData,
                channelData$: new BehaviorSubject(userData),
                remoteClients: [],
                remoteClient: null,
                remoteClient$: new BehaviorSubject(null),
                //remoteReady: new BehaviorSubject({status: false, description: null}),
                channelInfo$: new BehaviorSubject(null),
                chatData$: new BehaviorSubject(null),
            });
        }
    }

    public getChatData(channelId: string): Observable<any> {
        if (!channelId) {
            return of();
        }
        return this.channels[channelId].chatData$;
    }

    private startSocketConnection(channelId: string) {
        if (!channelId) {
            return;
        }

        if (!this.channels[channelId].activeWS) {
            const socketUserId = this.channels[channelId].channelData.userId;
            const userName = this.channels[channelId].channelData.userName;
            const accessToken = this.storage.getAccessToken();

            this.channels[channelId].activeWS = new RxWebsocketSubject(
                this.telemedRest.getWebSocketTelemedUrl() +
                    `${channelId}/${socketUserId}/${userName}?auth_token=${accessToken}`,
                {
                    subMsg: () =>
                        this.createSocketMessage(channelId, {
                            onTerm: true,
                        }),
                    unsubMsg: () => {
                        const channelData = new WebSocketChannelUser().deserialize({
                            userName: this.channels[channelId].channelData.userName,
                            userId: this.channels[channelId].channelData.userId,
                            displayName: this.channels[channelId].channelData.displayName,
                            userType: this.channels[channelId].channelData.userType,
                        });
                        this.setChannelData(channelId, channelData);
                        this.sendSocketAddTimeMessage(channelId);
                        return channelData;
                    },
                    messageFilter: (message: any) => {
                        if (_.isEmpty(message)) {
                            return false;
                        }
                        if (message.eventType == 'chat') {
                            return true;
                        }
                        if (message.eventType) {
                            return false;
                        }

                        if (!message.doctorStartTime && !message.patientStartTime && !message.userId) {
                            return false;
                        }
                        return true;
                    },
                },
            );

            this.channels[channelId].activeWSSubscription = this.channels[channelId].activeWS
                .pipe(
                    tap(() => {
                        this.initWebSocketResolver$.next(true);
                    }),
                    map((message: any) => {
                        // * Vračamo samo željene podatke
                        if (message.doctorStartTime || message.patientStartTime) {
                            //TODO WsMessage objekt zamenjaj z AS
                            return {
                                messageType: 'channelInfo',
                                data: message,
                            } as {
                                messageType: string;
                                data: WebSocketChannelInfo;
                            };
                        } else if (message.userId) {
                            return {
                                messageType: 'userInfo',
                                data: message,
                            } as {
                                messageType: string;
                                data: WebSocketChannelUser;
                            };
                        } else if (message.eventType == 'chat') {
                            return new WsMessage().deserialize({
                                messageType: WsMessageType.CHAT_DATA,
                                data: _.get(message, 'chat', []),
                            });
                        } else {
                            // console.error('Unknown message from socket');
                            // return null;
                        }
                    }),
                )
                .subscribe((message: { messageType: string; data: WebSocketChannelInfo | WebSocketChannelUser }) => {
                    if (message.messageType === 'channelInfo') {
                        this.channels[channelId].channelInfo = new WebSocketChannelInfo().deserialize(message.data);
                        this.channels[channelId].channelInfo$.next(this.channels[channelId].channelInfo);
                    }
                    if (message.messageType === 'userInfo') {
                        if (
                            (message.data as WebSocketChannelUser).userId ===
                            this.channels[channelId].channelData.userId
                        ) {
                            return;
                        }

                        if ((message.data as WebSocketChannelUser).onTerm) {
                            this.addRemote(channelId, message.data);
                        } else {
                            this.removeRemote(channelId, message.data);
                        }
                    }

                    if (message.messageType === WsMessageType.CHAT_DATA) {
                        this.channels[channelId].chatData$.next(message.data);
                    }

                    this.channels[channelId].activeWS.connectionStatus.subscribe((status) => {
                        this.connectionStatus$.next(status);
                    });
                });

            // @TODO Clear intervals na leave
            setInterval(() => {
                this.sendSocketAddTimeMessage(channelId);
            }, 60000);
        }
    }

    public closeSocket(channelId: string) {
        if (this.channels[channelId]) {
            if (this.channels[channelId].activeWSSubscription) {
                this.channels[channelId].activeWSSubscription.unsubscribe();
            }
            if (this.channels[channelId].activeWS) {
                this.channels[channelId].activeWS.close();
            }
        }
    }

    public sendSocketStartMessage(channelId: string): void {
        if (this.channels[channelId]) {
            this.channels[channelId].activeWS.send({
                eventType: 'start',
                source: this.getLocalUserType(channelId) === 'doctor' ? 'DOCTOR' : 'PATIENT',
                videoSession: true,
                recordedSession: false,
            });

            if (!this.channels[channelId].channelInfo || !this.channels[channelId].channelInfo.doctorStartTime) {
                this.channels[channelId].channelInfo = new WebSocketChannelInfo().deserialize({
                    doctorStartTime: new Date().getTime(),
                });
                this.channels[channelId].channelInfo$.next(this.channels[channelId].channelInfo);
            }

            //@TODO We could send event to chat (Powered by Dejan). But first we need some upgrades on socket server.
            //this.sendChatSocketMessage(channelId, 'Klic vzpostavljen');

            setTimeout(() => {
                this.sendSocketAddTimeMessage(channelId);
            }, 5000);
        }
    }

    public sendSocketAddTimeMessage(channelId: string) {
        if (this.callState === WsCallStateType.CALL_STARTED && channelId) {
            if (this.channels[channelId]) {
                this.channels[channelId].activeWS.send({
                    eventType: 'ADDTIME',
                    source: this.getLocalUserType(channelId) === 'doctor' ? 'DOCTOR' : 'PATIENT',
                });
            }
        }
    }

    public createSocketMessage(channelId: string, data: any) {
        if (!this.channels[channelId].channelData) {
            this.channels[channelId].channelData = new WebSocketChannelUser().deserialize(data);
            //this.channelData[channelId].userName = user.username;
        } else {
            this.channels[channelId].channelData = {
                ...this.channels[channelId].channelData,
                ...{ timestamp: new Date() },
                ...data,
            };
        }

        this.setChannelData(channelId, this.channels[channelId].channelData);
        return this.channels[channelId].channelData;
    }

    public sendSocketMessage(channelId: string, data: any = {}) {
        if (this.channels[channelId]) {
            const channel = this.channels[channelId].activeWS;

            const message = this.createSocketMessage(channelId, data);

            if (channel) {
                channel.send(message);
            }
        } else {
            console.error(`Error sending message to socket ${channelId}`);
        }
    }

    public sendChatSocketMessage(channelId: string, message: string, documents?: DocumentData[]): void {
        const data: any[] = [
            {
                eventType: 'chat',
                source: this.getLocalUserType(channelId).toUpperCase() === 'DOCTOR' ? 'DOCTOR' : 'PATIENT',
                chat: message,
                username: this.getLocalUserId(channelId),
                userid: this.getLocalUserName(channelId),
                eventTime: new Date(),
            },
        ];
        // if (documents) {
        //     data.document = document;
        // }

        documents.forEach((document, index) => {
            if (index == 0) {
                data[0].document = document;
            } else {
                data.push({
                    eventType: 'chat',
                    source: this.getLocalUserType(channelId).toUpperCase() === 'DOCTOR' ? 'DOCTOR' : 'PATIENT',
                    chat: null,
                    username: this.getLocalUserId(channelId),
                    userid: this.getLocalUserName(channelId),
                    eventTime: new Date(),
                    document: document,
                });
            }
        });
        if (this.channels[channelId]) {
            const channel = this.channels[channelId].activeWS;
            if (channel) {
                channel.send(data);
                this.channels[channelId].chatData$.next(data);
            }
        } else {
            console.error(`Error sending message to socket (generic) ${channelId}`);
        }
    }

    // public sendChatSocketMessage(channelId: string, message: string, document?: DocumentData ): void {
    //     debugger;
    //     const data: any = {
    //         eventType: 'chat',
    //         source: this.getLocalUserType(channelId).toUpperCase() === 'DOCTOR' ? 'DOCTOR' : 'PATIENT',
    //         chat: message,
    //         username: this.getLocalUserId(channelId),
    //         userid: this.getLocalUserName(channelId),
    //         eventTime: new Date(),
    //     };
    //     if (document) {
    //         data.document = document;
    //     }
    //     if (this.channels[channelId]) {
    //         const channel = this.channels[channelId].activeWS;
    //         if (channel) {
    //             channel.send(data);
    //             this.channels[channelId].chatData$.next([data]);
    //         }
    //     } else {
    //         console.error(`Error sending message to socket (generic) ${channelId}`);
    //     }
    // }

    public getCallState() {
        return this.callState$;
    }

    public setCallState(state: WsCallStateType) {
        if (this.callState !== state) {
            this.callState = state;
            this.callState$.next(state);
            // if (this.callState !== WsCallStateType.CALL_STARTED) {
            //     this.toggleModalSize('modal');
            // }
        }
    }

    private setChannelData(channelId: string, channelData: WebSocketChannelUser): void {
        this.channels[channelId].channelData = channelData;
        this.channels[channelId].channelData$.next(channelData);
    }

    public getChannelData(channelId: string): Observable<WebSocketChannelUser> {
        return new Observable((observer: Observer<WebSocketChannelUser>) => {
            const int$ = setInterval(() => {
                if (this.channels[channelId] && this.channels[channelId].channelData$) {
                    clearInterval(int$);
                    this.channels[channelId].channelData$.subscribe((res) => {
                        observer.next(res);
                    });
                }
            }, 100);
        });
    }

    public getLocalUserType(channelId: string) {
        return this.channels[channelId].channelData.userType;
    }

    public getLocalUserId(channelId: string) {
        return this.channels[channelId].channelData.userId;
    }

    public getLocalUserName(channelId: string) {
        return this.channels[channelId].channelData.userName;
    }

    public getRemote(channelId: string): WebSocketChannelUser {
        if (!this.channels[channelId]) {
            return null;
        }

        const lookingFor = this.channels[channelId].channelData.userType === 'doctor' ? 'patient' : 'doctor';

        let remoteCallIndex = this.channels[channelId].remoteClients.findIndex((remoteClient) => {
            //return remoteClient.userType === 'patient' && uid ? uid === remoteClient.uid : true;
            return (
                remoteClient.userType === lookingFor &&
                remoteClient.onTerm &&
                remoteClient.clientReady &&
                remoteClient.streamPublished
            );
        });

        if (remoteCallIndex === -1) {
            remoteCallIndex = this.channels[channelId].remoteClients.findIndex((remoteClient) => {
                //return remoteClient.userType === 'patient' && uid ? uid === remoteClient.uid : true;
                return remoteClient.userType === lookingFor && remoteClient.onTerm && remoteClient.clientReady;
            });
        }

        if (remoteCallIndex === -1) {
            remoteCallIndex = this.channels[channelId].remoteClients.findIndex((remoteClient) => {
                //return remoteClient.userType === 'patient' && uid ? uid === remoteClient.uid : true;
                return remoteClient.userType === lookingFor && remoteClient.onTerm;
            });
        }

        if (remoteCallIndex === -1) {
            return null;
        }

        const remoteCall = this.channels[channelId].remoteClients[remoteCallIndex];
        return remoteCall;
    }

    private addRemote(channelId: string, data: any, stream: boolean | Stream = null) {
        const existingClient = this.channels[channelId].remoteClients.findIndex((remoteClient) => {
            return remoteClient.userId === data.userId;
        });
        if (existingClient === -1) {
            this.channels[channelId].remoteClients.push(data);
        } else {
            this.channels[channelId].remoteClients[existingClient] = {
                ...this.channels[channelId].remoteClients[existingClient],
                ...data,
            };
        }
        this.checkRemotes(channelId);
    }

    private removeRemote(channelId: string, data: any) {
        const existingClient = this.channels[channelId].remoteClients.findIndex((remoteClient) => {
            return remoteClient.userId === data.userId || remoteClient.uid === data.uid;
        });
        if (existingClient !== -1) {
            this.channels[channelId].remoteClients.splice(existingClient, 1);
        }
        this.checkRemotes(channelId);
    }

    public checkRemotes(channelId: string) {
        if (this.channels[channelId]) {
            const existingCall = this.channels[channelId].remoteClients.find((remoteClient) => {
                //return remoteClient.userType === 'patient' && uid ? uid === remoteClient.uid : true;
                return (
                    remoteClient.userType === this.channels[channelId].channelData.userType &&
                    (remoteClient.inCall || remoteClient.streamPublished)
                );
            });

            if (existingCall) {
                this.outsideCallInProgress$.next(true);
            } else {
                this.outsideCallInProgress$.next(false);
            }
        } else {
            this.outsideCallInProgress$.next(false);
        }

        const patientClient = this.getRemote(channelId);
        if (patientClient) {
            this.channels[channelId].remoteClient = patientClient;
            this.channels[channelId].remoteClient$.next(patientClient);
        }
        return patientClient;
    }

    public getRemoteClient(channelId: string): Observable<WebSocketChannelUser> {
        return new Observable((observer: Observer<WebSocketChannelUser>) => {
            const int$ = setInterval(() => {
                if (this.channels[channelId] && this.channels[channelId].remoteClient$) {
                    clearInterval(int$);
                    this.channels[channelId].remoteClient$.subscribe((res) => {
                        observer.next(res);
                    });
                }
            }, 100);
        });
    }

    public parseUserData(reservationInfo: any): WebSocketChannelUser {
        let userType = this.storage.getUserType();

        let displayName = 'Uporabnik';
        let userName = 'Uporabnik';

        if (userType === 'Doctor') {
            displayName = 'Zdravnik';
            userName = reservationInfo.doctorId;
            userType = 'doctor';
        } else {
            displayName = `${reservationInfo.patientName} ${reservationInfo.patientSurname}`;
            userName = reservationInfo.patientId;
            userType = 'patient';
        }

        return new WebSocketChannelUser().deserialize({
            userName: userName,
            userId: this.getSocketUserId(userName),
            displayName: displayName,
            userType: userType,
        });
    }

    public endCall(channelId: string) {
        if (this.callState === WsCallStateType.CALL_STARTED) {
            this.setCallState(WsCallStateType.CALL_ENDING);
        } else {
            this.setCallState(WsCallStateType.DEVICE_TEST);
        }
        this.sendSocketMessage(channelId, {
            clientReady: false,
        });
    }

    public getOutsideCallInProgress(): Observable<boolean> {
        return this.outsideCallInProgress$;
    }

    public getConnectionStatus(channelId: string) {
        if (!channelId) {
            console.log('ni channel idja');
            this.connectionStatus$.next(false);
        }

        if (!(this.channels[channelId] && this.channels[channelId].activeWS)) {
            this.connectionStatus$.next(false);
        }

        return this.connectionStatus$;
    }

    private getSessionId() {
        let sessionId: string = this.storage.getSocketSessionId();
        if (!sessionId) {
            sessionId = Math.random().toString();
            this.storage.setSocketSessionId(sessionId);
        }
        return sessionId;
    }

    private getSocketUserId(userId: number | string): string {
        const sessionId = this.getSessionId();
        return userId.toString() + sessionId;
    }

    public callTimer(e?) {
        if (e) {
            this.$callTimer = interval(1000);
            this.timer = this.$callTimer.subscribe((n) => {
                console.log(n);
                this.$callTotalTime = this.$callTotalTime + 1000;
                sessionStorage.setItem('callTotalTime', String(this.$callTotalTime));
                this.caseService.emitData(this.$callTotalTime);
            });
        } else {
            if (this.timer) {
                this.timer.unsubscribe();
            }
        }
    }
}
