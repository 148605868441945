import { Component, OnInit } from '@angular/core';
import {TelemedicineVideoComponent} from "../telemedicine-video.component";

@Component({
  selector: 'app-generali-video-component',
  templateUrl: './generali-video-component.component.html',
  styleUrls: ['./generali-video-component.component.scss']
})
export class GeneraliVideoComponentComponent extends TelemedicineVideoComponent implements OnInit  {
    
  ngOnInit(): void {
      super.ngOnInit();
  }

}
