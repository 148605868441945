import { StorageService } from './_services/storage.service';
import { HelperService } from './_services/helper.service';
import { tap } from 'rxjs/operators';
import { GuidInfo } from './_model/common/guid-info.model';
import { CommonService } from './_services/common.service';
import {
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Helpers } from './helpers';
import { slideInAnimation } from './animations';

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
        slideInAnimation,
    ],
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass =
        'm-page--loading-non-block m-page--fluid m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

    constructor(
        private _router: Router,
        private route: ActivatedRoute,
        private commonRest: CommonService,
        private helper: HelperService,
        private storage: StorageService,
    ) {}

    public guid: string;
    public cData: GuidInfo;
    public isGuid: boolean;

    ngOnInit() {
        this.storage.clearBasicStorage();
        Helpers.setLoading(true);
        this.route.queryParams.subscribe((params) => {
            if (!params.guid || this._router.url.includes('/reservations')) {
                return;
            } else {
                this.guid = params.guid;
                let isDoctor: boolean = this._router.url.includes('/public/eposvet/doctor');

                this.commonRest
                    .getGuidInfo(this.guid, isDoctor)
                    .pipe(
                        tap((response: GuidInfo) => {
                            if (response?.bsid && response?.bsid != 111) {
                                this.cData = response;
                                let setBrand = '';
                                if (response?.reservationType && response.reservationType === 'WIDGET_VOUCHER') {
                                    setBrand = 'v-c-medical';
                                } else {
                                    sessionStorage.setItem('bs', response.bsid + '');
                                    setBrand = 'b-c' + response.bsid;
                                }
                                Helpers.htmlClass(setBrand);
                            } else if (response?.vendor) {
                                this.cData = response;
                                sessionStorage.setItem('vendor', response.vendor + '');
                                const setBrand = 'v-c-' + response?.vendor;
                                Helpers.htmlClass(setBrand);
                                this.helper.setTitleVendor(response?.vendor);
                                //TODO
                                // this.renderer.addClass(document.doctype, setBrand);
                            } else if (response?.contractorId) {
                                this.cData = response;
                                // sessionStorage.setItem('caseData', JSON.stringify(response));
                                sessionStorage.setItem('contractor', response.contractorId + '');
                                const setBrand = 'v-c-medifit c-c-' + response?.contractorId;
                                Helpers.htmlClass(setBrand);
                                this.helper.setTitleVendor(response?.contractorName);
                                //TODO
                                // this.renderer.addClass(document.doctype, setBrand);
                            } else {
                                sessionStorage.removeItem('bs');
                            }
                            if (response?.code) {
                                sessionStorage.setItem('2fa', String(response.code));
                            }
                            sessionStorage.setItem('caseData', JSON.stringify(response));
                        }),
                    )
                    .subscribe(
                        () => {},
                        (error) => {
                            sessionStorage.removeItem('bs');
                            Helpers.setLoading(false);
                        },
                    );
            }
        });
        Helpers.setLoading(true);
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }

            if (route instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
