import { Slot } from './slot.model';
import * as moment from 'moment';
import { Serializable } from '../deserialize.interface';

export class DaySlot implements Serializable<DaySlot> {
    date: moment.Moment;
    slots: Slot[];
    deserialize(input) {
        this.date = moment(input.date);
        this.slots = input.slots;

        return this;
    }
}
