import { DocumentSign } from './../../../_model/common/document-sign.model';
import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
@Component({
    selector: 'app-media-view-popup',
    templateUrl: './media-view-popup.component.html',
    styleUrls: ['./media-view-popup.component.scss'],
})
export class MediaViewPopupComponent implements OnInit {
    @Input()
    set setdocumentFile(data: any) {
        this.documentFile = URL.createObjectURL(data);
        this.blobData = data;
        if (data) {
            if (data.type.includes('video')) {
                // this.isVideo = true;
                this.videoPlayer.element.nativeElement.src = this.documentFile;
            } else if (data.type == 'text/plain') {
                var reader = new FileReader();
                const that = this;
                reader.onload = function () {
                    that.documentFile = reader.result;
                };
                reader.readAsText(data);
            } else {
                this.iframe.element.nativeElement.src = this.documentFile;
            }
        }
    }
    @Input()
    documentType: any;

    @Input()
    fileName: string;

    @ViewChild('blob', { read: ViewContainerRef, static: true })
    iframe: any;

    @ViewChild('videoPlayer', { read: ViewContainerRef, static: true })
    videoPlayer: any;

    @Input()
    signature: DocumentSign;

    // @Output() public action: Subject<any> = new Subject();
    public isVideo: boolean = false;
    public blobData: any;
    public documentFile: any;
    public zoom = 0.6;
    public fullScreenEnabled = false;
    public widthPic: any;
    public width: number = 50;
    public navigationButtons: boolean = false;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {}

    // public checkIfEmpty(signature: DocumentSign): boolean {
    //     return _.compact(_.values(signature)).length < 1;
    // }
    //ko klikneš drugam kakor pdf/slika, ti zapre popup
    public clickEvent(event: any) {
        if (
            event.srcElement.className === 'textLayer' ||
            event.srcElement.className === 'center' ||
            event.srcElement.className === 'd-flex' ||
            event.srcElement.className === 'm--font-boldest' ||
            event.srcElement.className === ''
        ) {
            return;
        } else {
            this.activeModal.dismiss('Close click');
        }
    }
    // public nextFile(data: string) {
    //     this.action.next({ action: data, data: this.blobData });
    // }

    public appendDocumentToCase(): void {
        this.activeModal.close({ action: 'appendDocumentToCase' });
    }

    // public test(): void {
    //     console.log('test');
    // }
    public zoom_in() {
        if (this.documentType === 'application/pdf') {
            this.zoom += 0.25;
        }
        if (this.documentType.includes('image')) {
            if (this.width == 100) {
                return;
            }
            this.width += 10;
            this.widthPic = this.width + '%';
        }
    }

    public zoom_out() {
        if (this.documentType === 'application/pdf') {
            if (this.zoom == 0.25) {
                return;
            }
            this.zoom -= 0.25;
        }
        if (this.documentType.includes('image')) {
            if (this.width == 10) {
                return;
            }
            this.width -= 10;
            this.widthPic = this.width + '%';
        }
    }
    public fullscreen() {
        if (this.documentType === 'application/pdf') {
            if (!this.fullScreenEnabled) {
                this.zoom = 1;
                this.fullScreenEnabled = true;
            } else {
                this.zoom = 0.38;
                this.fullScreenEnabled = false;
            }
        }
    }

    public downloadFile(): void {
        FileSaver.saveAs(this.blobData, this.fileName);
    }

    public printFile(): void {
        try {
            //Chrome
            this.iframe.element.nativeElement.contentWindow.focus();
            this.iframe.element.nativeElement.contentWindow.print();
        } catch (err) {
            //Firefox
            // this.mfToast.warning('Vaš brskalnik ne podpira tiskanja.');
            this.downloadFile();
        }
    }

    public stopLoading(): void {
        // Helpers.setLoading(false);
    }
}
