import { Serializable } from './../../_services/deserialize.service';

export class WebSocketChannelInfo implements Serializable<WebSocketChannelInfo> {
    doctorSessionLength: number;
    doctorStartTime: number;
    isRecorded: boolean;
    isVideo: boolean;
    patientSessionLength: number;
    patientStartTime: number;
    startTime: number;

    deserialize(input) {
        this.doctorSessionLength = input.doctorSessionLength;
        this.doctorStartTime = input.doctorStartTime;
        this.isRecorded = input.isRecorded;
        this.isVideo = input.isVideo;
        this.patientSessionLength = input.patientSessionLength;
        this.patientStartTime = input.patientStartTime;

        if (input.startTime) {
            this.startTime = input.startTime;
        } else if (this.doctorStartTime && this.patientStartTime) {
            this.startTime =
                this.patientStartTime < this.doctorStartTime ? this.patientStartTime : this.doctorStartTime;
        } else if (this.doctorStartTime) {
            this.startTime = this.doctorStartTime;
        } else if (this.patientStartTime) {
            this.startTime = this.patientStartTime;
        }

        return this;
    }
}
