import { Service } from './../common/service.model';
import { Slot } from './slot.model';
import * as moment from 'moment';
import { Serializable } from '../deserialize.interface';

export class ReservationInfo implements Serializable<ReservationInfo> {
    guid: string;
    contractor: any;
    customer: any;
    service: Service;
    startDate: moment.Moment;
    maskedContractorId: string;

    deserialize(input) {
        this.guid = input.guid;
        this.contractor = input.contractor;
        this.customer = input.customer;
        this.service = input.service;
        this.startDate = input.startDate;
        this.maskedContractorId = input.maskedContractorId;

        return this;
    }
}

// import { Slot } from './slot.model';
// import * as moment from 'moment';
// import { Serializable } from '../deserialize.interface';

// export class ReservationInfo implements Serializable<ReservationInfo> {
//     date: moment.Moment;
//     slots: Slot[];
//     deserialize(input) {
//         this.date = input.date;
//         this.slots = input.slots;

//         return this;
//     }
// }
