import {Serializable} from "../_services/deserialize.service";

export class Business implements Serializable<Business> {
    bsid: number;
    iconPath: string;
    name: string;
    component;

    deserialize(input) {
        this.bsid = input.bsid;
        this.iconPath = input.iconPath;
        this.name = input.name;
        this.component = input.component;
        return this;
    }
}