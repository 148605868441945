import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class TelemedicineService {
    private wizardVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(private httpClient: HttpClientService, private storage: StorageService) {}

    public getReservationInfo(guid: string): Observable<any> {
        const token = sessionStorage.getItem('2fa');
        return this.httpClient.get(this.getPatientUrl() + `access/token/${guid}/code/${token}`, {});
    }

    public getTokenData(isDoctor: boolean, channel: string, vendor: string, contractorId: string): Observable<any> {
        let tokenUrl = '';
        if (contractorId) {
            tokenUrl = `${this.getTelemedUrl()}/contractors/${contractorId}/prereservations/${channel}`;
        }

        if (vendor) {
            tokenUrl = `${this.getEPosvetUrl()}/vendors/${vendor}/prereservations/${channel}`;
        }

        const accessToken = this.storage.getAccessToken();

        return this.httpClient
            .post(
                tokenUrl,
                {
                    appId: environment.agora.appId,
                    isDoctor: isDoctor,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            )
            .pipe(
                catchError((error) => {
                    return of(error);
                    /*
                return of({
                    uid: 5 + Math.floor(Math.random() * 1000),
                    token: null,
                });
                */
                }),
            );
    }

    //Move
    public getWebSocketTelemedUrl(): string {
        return (
            environment.webSocketTelemed.protocol +
            environment.webSocketTelemed.rootUrl +
            environment.webSocketTelemed.port +
            environment.webSocketTelemed.url
        );
    }

    public getTelemedUrl(): string {
        //http://localhost:9090/booking/patient/api
        return (
            environment.telemed.protocol +
            environment.telemed.rootUrl +
            environment.telemed.port +
            environment.telemed.url
        );
    }

    public getEPosvetUrl(): string {
        //http://localhost:9090/booking/patient/api
        return (
            environment.ePosvet.protocol +
            environment.ePosvet.rootUrl +
            environment.ePosvet.port +
            environment.ePosvet.url
        );
    }

    public getPatientUrl(path?: string): string {
        //http://localhost:9090/booking/patient/api

        if (!path) {
            path = '';
        }
        return (
            environment.patient.protocol +
            environment.patient.rootUrl +
            environment.patient.port +
            environment.patient.url +
            '/' +
            path
        );
    }

    public setWizardState(value: boolean): void {
        this.wizardVisible$.next(value);
    }

    public getWizardState(): Observable<boolean> {
        return this.wizardVisible$.pipe(distinctUntilChanged());
    }
}
