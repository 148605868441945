import { Customer } from './../../_model/common/customer.model';
import { Slot } from './../../_model/reservations/slot.model';
import { ResSelectSlotResult } from './../../_model/reservations/res-select-slot-result.model';
import { ReservationInfo } from './../../_model/reservations/reservation-info.model';
import * as moment from 'moment';
export namespace BaseStateActions {
    export class ClearBaseState {
        public static readonly type = '[state] ClearBaseState';
    }

    export namespace Reservations {
        export class SetReservationInfo {
            public static readonly type = '[reservations] SetReservationInfo';
            constructor(public readonly reservationInfo: ReservationInfo) {}
        }
        export class UpdateReservationInfo {
            public static readonly type = '[reservations] UpdateReservationToken';
            constructor(public readonly name: string, public readonly value: any) {}
        }
        export class SetReservationParams {
            public static readonly type = '[reservations] SetReservationParams';
            constructor(public readonly resParams: ResSelectSlotResult) {}
        }

        export class SetReservationToken {
            public static readonly type = '[reservations] SetReservationToken';
            constructor(public readonly token: string) {}
        }
        export class GetSlotsData {
            public static readonly type = '[reservations] GetReservationData';
            constructor(public readonly guid: string) {}
        }
    }

    export namespace Widget {
        export class GetSlotsData {
            public static readonly type = '[widget] GetWidgetSlotsData';
            constructor(public readonly from: moment.Moment, public readonly to: moment.Moment) {}
        }

        export class SetSelectedSlot {
            public static readonly type = '[widget] SetSelectedSlot';
            constructor(public readonly slot: Slot) {}
        }

        export class SetCustomer {
            public static readonly type = '[widget] SetCustomer';
            constructor(public readonly customer: Customer) {}
        }
    }
}
