export enum LocalAvalaibleEnum {
    READY = '',
    WAITING = 'Čakam na dostop do kamere...',
    NotAllowedError = 'Dostop do kamere ali mikrofona ni mogoč! Priporočamo spremembo nastavitev vašega brskalnika in operacijskega sistema. Če želite nadaljevati brez kamere ali mikrofona, kliknite na "Začnite ePosvet"',
    PERMISSION_DENIED = 'Dostop do kamere ali mikrofona ni mogoč! Priporočamo spremembo nastavitev vašega brskalnika in operacijskega sistema. Če želite nadaljevati brez kamere ali mikrofona, kliknite na “Začnite ePosvet',
    NotFoundError = 'Ni ustrezne kamere ali mikrofona! Preverite ali sta kamera in mikrofon priključena in omogočena.',
    DEVICES_NOT_FOUND = 'Ni ustrezne kamere ali mikrofona! Preverite ali sta kamera in mikrofon priključena in omogočena.',
    MEDIA_OPTION_INVALID = 'Kamera je že v uporabi ali ni ustrezne kakovosti.',
    NOT_SUPPORTED = 'Vaš brskalnik ne podpira uporabe kamere.',
}
