import { VendorType } from './../../_enums/vendorType.enum';
import { Serializable } from './../../_services/deserialize.service';
import {WebSocketChannelUser} from './webSocketChannelUser.model';
import {WebSocketChat} from './web-socket-chat.model';
import {WebSocketChannelInfo} from './web-socket-channel-info.model';

export class WsMessage implements Serializable<WsMessage> {
    messageType: VendorType;
    data: WebSocketChannelUser | WebSocketChannelInfo | WebSocketChat;

    deserialize(input) {
        this.messageType = input.messageType;
        this.data = input.data;
        return this;
    }
}
