import { WebsocketClientService } from './websocket-client.service';
import { WsCallStateType } from './../_enums/ws-call-state-type.enum';
import { StorageService } from './storage.service';
import { environment } from './../../environments/environment';
import { AgoraClient, AgoraRTC, ClientConfig, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalAvalaibleEnum } from '../_enums/localAvalaible.enum';
import { TelemedicineService } from './telemedicine.service';
import { WebSocketChannelUser } from '../_model/telemedicine/webSocketChannelUser.model';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TelemedicineAgoraService {
    private client: AgoraClient;
    private telemedInitialised: boolean = false;
    private appId: string = environment.agora.appId;
    private connected: boolean = false;
    private localStream: Stream;
    private remoteStream: Stream;
    private outsideCallInProgress: boolean = false;
    private setVolumeTo: number = 100;
    public audioVolume: number = 100;
    public audioVolume$: BehaviorSubject<number> = new BehaviorSubject(100);

    private channelId: string = null;
    //private remoteStreams: Stream[] = [];

    public localAvalaible: { initial: LocalAvalaibleEnum; final: LocalAvalaibleEnum } = {
        initial: null,
        final: LocalAvalaibleEnum.WAITING,
    };

    public localAvalaible$: BehaviorSubject<{
        initial: LocalAvalaibleEnum;
        final: LocalAvalaibleEnum;
    }> = new BehaviorSubject(this.localAvalaible);

    private videoDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);
    private audioDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);
    private playoutDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);

    private activeDevices: {
        camera: MediaStreamTrack;
        audio: MediaStreamTrack;
        playout: MediaDeviceInfo;
    } = {
        camera: null,
        audio: null,
        playout: null,
    };

    private activeDevices$: BehaviorSubject<{
        camera: MediaStreamTrack;
        audio: MediaStreamTrack;
        playout: MediaDeviceInfo;
    }> = new BehaviorSubject(this.activeDevices);

    private localAudioEnabled: boolean = false;
    private localVideoEnabled: boolean = false;

    private lastFallBackCombination = 0;
    private fallBackCombinations = [
        {
            video: true,
            audio: true,
        },
        {
            video: true,
            audio: false,
        },
        {
            video: false,
            audio: true,
        },
    ];

    private streamProfiles = {
        '720p': {
            agoraProfile: '720p_3',
            audio: true,
            video: true,
        },
        '480p': {
            agoraProfile: '480p_1',
            audio: true,
            video: true,
        },
    };

    private proxyEnableContracotrs = ['12307', '05900'];

    constructor(
        private agoraService: NgxAgoraService,
        private storage: StorageService,
        private wsClient: WebsocketClientService,
        private telemedRest: TelemedicineService,
    ) {}

    public initAgora(channelId: string): Observable<boolean> {
        const response: ReplaySubject<boolean> = new ReplaySubject(1);

        if (this.telemedInitialised) {
            response.next(true);
            response.complete();
            return response;
        }

        this.agoraService.AgoraRTC.Logger.setLogLevel(environment.agora.logLevel);

        if (channelId) {
            this.channelId = channelId;
        }
        //this.currentCallChannel$.next(this.channel);

        //const userId = Math.floor(Math.random() * 100);
        if (!this.client) {
            this.telemedRest.getReservationInfo(channelId).subscribe((resp) => {
                const agoraConf: ClientConfig = {
                    mode: 'rtc',
                    codec: 'vp8',
                };

                if (
                    this.proxyEnableContracotrs.findIndex((contractorId) => {
                        return contractorId === resp.contractorId;
                    }) !== -1
                ) {
                    agoraConf.proxyServer = environment.agora.proxyServer;
                    agoraConf.turnServer = environment.agora.turnServer;
                }

                this.client = this.agoraService.createClient(agoraConf);
                this.assignClientHandlers();

                this.client.init(
                    this.appId,
                    () => {
                        console.log('Initialized successfully');
                        response.next(true);
                        response.complete();
                        //this.getCameras();
                        //this.initLocalMedia();
                    },
                    () => {
                        console.log('Could not initialize');
                        response.error(false);
                        response.complete();
                    },
                );
            });
        } else {
            response.next(true);
            response.complete();
        }

        this.telemedInitialised = true;

        return response;
    }

    public initLocalMedia(uid: number, profileId?: string, channelId?: string) {
        this.initAgora(channelId).subscribe(() => {
            const combination = this.fallBackCombinations[this.lastFallBackCombination];
            this.localStream = this.agoraService.createStream({
                streamID: uid,
                audio: combination.audio,
                video: combination.video,
                screen: false,
            });
            this.localStream.setVideoProfile(this.getProfile(profileId).agoraProfile);
            this.assignLocalStreamHandlers();

            this.localStream.init(
                () => {
                    this.setLocalAvalaible(LocalAvalaibleEnum.READY);
                    console.log('getUserMedia successfully');

                    this.toggleAudio(combination.audio);
                    this.toggleVideo(combination.video);

                    this.localStream.play('agora-local-video');

                    this.wsClient.sendSocketMessage(this.channelId, {
                        cameraReady: combination.video,
                        microphoneReady: combination.audio,
                        cameraError: !combination.video,
                        microphoneError: !combination.audio,
                    });
                },
                (err: any) => {
                    console.error('getUserMedia failed', err);

                    this.setLocalAvalaible(LocalAvalaibleEnum[err.msg]);

                    this.lastFallBackCombination++;
                    if (this.lastFallBackCombination < this.fallBackCombinations.length) {
                        this.localStream.close();
                        this.initLocalMedia(uid);
                        return;
                    }

                    this.toggleAudio(false);
                    this.toggleVideo(false);

                    this.wsClient.sendSocketMessage(this.channelId, {
                        cameraReady: false,
                        cameraError: true,
                        microphoneReady: false,
                        microphoneError: true,
                    });
                },
            );
        });
    }

    private assignLocalStreamHandlers(): void {
        this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
            this.loadCameras();
            this.loadRecordingDevices();
            this.loadPlayoutDevices();
        });

        // The user has denied access to the camera and mic.
        this.localStream.on(StreamEvent.MediaAccessDenied, () => {
            console.error('accessDenied');
        });
    }

    private assignClientHandlers(): void {
        this.client.on(ClientEvent.LocalStreamPublished, (evt) => {
            this.wsClient.sendSocketMessage(this.channelId, {
                streamPublished: true,
            });
            console.log('Publish local stream successfully');
        });

        this.client.on(ClientEvent.Error, (error) => {
            console.error('Got error msg:', error.reason);
            if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
                this.client.renewChannelKey(
                    '',
                    () => console.log('Renewed the channel key successfully.'),
                    (renewError) => console.error('Renew channel key failed: ', renewError),
                );
            }
            // if (error.reason === 'SOCKET_DISCONNECTED') {
            //     this.lostConnection = true;
            // }
        });

        this.client.on(ClientEvent.RemoteStreamAdded, (evt) => {
            const newStream = (this.remoteStream = evt.stream as Stream);

            /*
            const existingStream = this.remoteStreams.find((stream) => {
                return stream.getId() === newStream.getId();
            });

            if (!existingStream) {
                this.remoteStreams.push(newStream);
            }
            */

            if (!this.outsideCallInProgress) {
                this.client.subscribe(newStream, { audio: true, video: true }, (err) => {
                    console.error('Subscribe stream failed', err);
                });
                this.setAudioVolume(this.setVolumeTo);
            } else {
                this.connected = false;
                this.client.leave();
            }
        });

        this.client.on(ClientEvent.RemoteStreamRemoved, (evt) => {
            const oldStream = (this.remoteStream = evt.stream as Stream);

            /*
            const existingStream = this.remoteStreams.findIndex((stream) => {
                return stream.getId() === oldStream.getId();
            });

            if (existingStream !== -1) {
                this.remoteStreams.slice(existingStream, 1);
            }
            */

            if (oldStream) {
                oldStream.stop();
                oldStream.close();
                console.log(`Remote stream is removed ${oldStream.getId()}`);
                //this.doctorReady = false;
                //this.remoteCall = null;
            }
        });

        this.client.on(ClientEvent.RemoteStreamSubscribed, (evt) => {
            const stream = (this.remoteStream = evt.stream as Stream);
            this.wsClient.setCallState(WsCallStateType.CALL_STARTED);
            this.setAudioVolume(this.setVolumeTo);

            this.wsClient.sendSocketMessage(this.channelId, {
                inCall: true,
            });
            this.wsClient.sendSocketStartMessage(this.channelId);

            setTimeout(() => {
                stream.play('agora-remote-video');
            }, 50);

            // this.remoteVideoCheck = setInterval(() => {
            //     const children = this.remoteVideo?.element?.nativeElement?.children;
            //     if (children?.length > 0) {
            //         clearInterval(this.remoteVideoCheck);
            //         if (children[0].children[0]) {
            //             children[0].children[0].removeAttribute('controls');
            //         }
            //     }
            // }, 300);

            this.wsClient.callTimer(true);
        });

        this.client.on(ClientEvent.PeerLeave, (evt) => {
            const oldStream = (this.remoteStream = evt.stream as Stream);
            /*
            const existingStream = this.remoteStreams.findIndex((stream) => {
                return stream.getId() === oldStream.getId();
            });

            if (existingStream !== -1) {
                this.remoteStreams.slice(existingStream, 1);
            }
            */

            if (oldStream) {
                oldStream.stop();
                oldStream.close();
                console.log(`${evt.uid} left from this channel`);
                //this.doctorReady = false;
                //this.remoteCall = null;
            }
        });

        this.client.on(ClientEvent.PeerOnline, (evt) => {
            console.log(`${evt.uid} online on agora`);
        });
    }

    join(remoteClient: WebSocketChannelUser): void {
        /*
        if (!this.enableTelemedicine || this.localAvailable.final === LocalAvalaibleEnum.WAITING || this.outsideCallInProgress) {
            return;
        }
        */

        if (this.connected) {
            return;
        }

        this.telemedRest
            .getTokenData(
                this.wsClient.getLocalUserType(this.channelId) === 'doctor' ? true : false,
                this.channelId,
                this.storage.getVendor(),
                this.storage.getContractorId(),
            )
            .subscribe(
                (response) => {
                    this.client.join(
                        response.token,
                        this.channelId,
                        response.uid,
                        () => {
                            this.wsClient.sendSocketMessage(this.channelId, {
                                clientJoined: true,
                                uid: response.uid,
                            });
                            this.connected = true;
                            setTimeout(() => {
                                this.publish();
                            }, 10);

                            //@TODO Should be turned around
                            if (remoteClient.cameraError && remoteClient.microphoneError) {
                                if (!this.outsideCallInProgress) {
                                    this.setAudioVolume(this.setVolumeTo);
                                    this.wsClient.setCallState(WsCallStateType.CALL_STARTED);
                                    this.wsClient.sendSocketMessage(this.channelId, {
                                        inCall: true,
                                    });
                                    this.wsClient.sendSocketStartMessage(this.channelId);
                                } else {
                                    //this.published = false;
                                    this.wsClient.sendSocketMessage(this.channelId, {
                                        streamPublished: false,
                                    });
                                    this.connected = false;
                                    this.client.leave();
                                }
                            }
                        },
                        () => {
                            this.wsClient.sendSocketMessage(this.channelId, {
                                clientJoined: false,
                                inCall: false,
                                uid: response.uid,
                            });
                        },
                    );
                },
                (err) => {
                    //@TODO Show error on token issue
                },
            );
    }

    leave(): void {
        if (this.connected) {
            this.unpublish();
            this.client.leave(
                () => {
                    console.log('Left the channel successfully');
                    this.connected = false;
                    this.wsClient
                        .getCallState()
                        .pipe(take(1))
                        .subscribe((callState) => {
                            if (callState === WsCallStateType.CALL_STARTING) {
                                this.wsClient.setCallState(WsCallStateType.DEVICE_TEST);
                            }

                            if (callState === WsCallStateType.CALL_STARTED) {
                                /*if (!this.doctorReady) {
                                //this.join();
                                setTimeout(() => this.leave(), 1000);
                            } else {
                                this.wsClient.setCallState(WsCallStateType.CALL_ENDING);
                            }
                            */
                                this.wsClient.setCallState(WsCallStateType.CALL_ENDING);
                            }

                            this.wsClient.sendSocketMessage(this.channelId, {
                                clientJoined: false,
                                inCall: false,
                            });
                        });
                },
                (err) => {
                    console.error('Leave channel failed');
                },
            );
            this.wsClient.callTimer(false);
        } else {
            //this.agoraService.AgoraRTC.Logger.warning('Local client is not connected to channel.');
        }
    }

    private publish(): void {
        this.client.publish(this.localStream, (err) => console.log('Publish local stream error: ' + err));
    }

    private unpublish(): void {
        this.client.unpublish(this.localStream, (error) => {
            console.error(error);
        });
        this.wsClient.sendSocketMessage(this.channelId, {
            streamPublished: false,
        });
    }

    public getLocalAvalaible(): Observable<{ initial: LocalAvalaibleEnum; final: LocalAvalaibleEnum }> {
        return this.localAvalaible$;
    }

    private setLocalAvalaible(status: LocalAvalaibleEnum) {
        if (this.localAvalaible.initial === null) {
            this.localAvalaible.initial = status;
        }
        this.localAvalaible.final = status;
        this.localAvalaible$.next(this.localAvalaible);
    }

    private loadCameras() {
        this.client.getCameras((devices: MediaDeviceInfo[]) => {
            const videoDevices = devices.filter((device) => {
                return device.deviceId !== 'default';
            });
            this.videoDevices$.next(videoDevices);
        });
    }

    public getCameras(): Observable<MediaDeviceInfo[]> {
        return this.videoDevices$;
    }

    private loadRecordingDevices() {
        this.client.getRecordingDevices((devices: MediaDeviceInfo[]) => {
            const audioDevices = devices.filter((device) => {
                return device.deviceId !== 'default';
            });
            this.audioDevices$.next(audioDevices);
        });
    }

    public getRecordingDevices(): Observable<MediaDeviceInfo[]> {
        return this.audioDevices$;
    }

    private loadPlayoutDevices() {
        this.client.getPlayoutDevices((devices: MediaDeviceInfo[]) => {
            const playoutDevices = devices.filter((device) => {
                return device.deviceId !== 'default';
            });
            this.playoutDevices$.next(playoutDevices);

            if (!this.activeDevices.playout) {
                this.setActiveDevices(null, null, devices[0]);
            }
        });
    }

    public getPlayoutDevices(): Observable<MediaDeviceInfo[]> {
        return this.playoutDevices$;
    }

    public switchCamera(camera: any) {
        // newVideoTrack vrne readyState naprave v "ended" namesto "live" kar pokvari kamero
        // const newVideoTrack = this.localStream.getVideoTrack();
        // this.localStream.replaceTrack(<any>newVideoTrack);
        this.localStream.switchDevice('video', camera.deviceId);
        //this.activeVideoDevice = camera.label;
        this.setActiveDevices(camera.label);
    }

    public switchAudio(audio: any) {
        const newAudioTrack = this.localStream.getAudioTrack();
        this.localStream.replaceTrack(<any>newAudioTrack);
        this.localStream.switchDevice('audio', audio.deviceId);
        //this.activeAudioDevice = audio.label;
        this.setActiveDevices(null, audio.label);
    }

    public switchPlayoutDevice(device: MediaDeviceInfo) {
        this.localStream.setAudioOutput(device.deviceId, () => {
            this.setActiveDevices(null, null, device);
        });
    }

    private setActiveDevices(camera?, audio?, playout?) {
        if (camera) {
            this.activeDevices.camera = camera;
        }

        if (audio) {
            this.activeDevices.audio = audio;
        }

        if (playout) {
            this.activeDevices.playout = playout;
        }

        this.activeDevices$.next(this.activeDevices);
    }

    public getActiveDevices(): Observable<any> {
        return this.activeDevices$;
    }

    public getAudioVolume() {
        return this.audioVolume$;
    }

    public setAudioVolume(setTo: number = null) {
        if (setTo !== null) {
            this.setVolumeTo = setTo;
        } else {
            if (this.audioVolume > 50) {
                this.setVolumeTo = 50;
            } else if (this.audioVolume > 0) {
                this.setVolumeTo = 0;
            } else if (this.audioVolume === 0) {
                this.setVolumeTo = 100;
            }
        }
        this.audioVolume = this.setVolumeTo;
        if (this.remoteStream) {
            this.remoteStream.setAudioVolume(this.setVolumeTo);
        }
        this.wsClient.sendSocketMessage(this.channelId, {
            playoutMuted: this.setVolumeTo > 0 ? false : true,
        });

        //this.localStream.setVolumeOfEffect(1, this.setVolumeTo);
        /*if (this.localStream) {
            this.localStream.setVolumeOfEffect(1, setTo);
        }*/
        this.audioVolume$.next(setTo);
    }

    public toggleAudio(forceTo?: boolean) {
        if (!this.localAudioEnabled && this.localAudioEnabled !== forceTo) {
            if (this.localStream.unmuteAudio() as unknown) {
                this.localStream.enableAudio();
            }
            this.localAudioEnabled = true;
        } else if (this.localAudioEnabled !== forceTo) {
            if (this.localStream.muteAudio() as unknown) {
                this.localStream.disableAudio();
            }
            this.localAudioEnabled = false;
        }
        this.wsClient.sendSocketMessage(this.channelId, {
            microphoneMuted: !this.localAudioEnabled,
        });
    }

    public toggleVideo(forceTo?: boolean) {
        if (!this.localVideoEnabled && this.localVideoEnabled !== forceTo) {
            if (this.localStream.unmuteVideo() as unknown) {
            }
            this.localVideoEnabled = true;
        } else if (this.localVideoEnabled !== forceTo) {
            if (this.localStream.muteVideo() as unknown) {
            }
            this.localVideoEnabled = false;
        }
        this.wsClient.sendSocketMessage(this.channelId, {
            cameraMuted: !this.localVideoEnabled,
        });
    }

    private getProfile(profileId: string) {
        let profile = '720p';
        if (this.streamProfiles[profileId]) {
            profile = profileId;
        }
        return this.streamProfiles[profile];
    }
}
