import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    constructor() {}

    getItem(key: string) {
        return localStorage.getItem(key);
    }

    setItem(key: string, content: any) {
        if (typeof content !== 'string') {
            content = JSON.stringify(content);
        }
        localStorage.setItem(key, content);
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    appendObject(key: string, content: any) {
        let existingContent = JSON.parse(this.getItem(key));
        if (!existingContent) {
            existingContent = {};
        }

        existingContent = { ...existingContent, ...content };
        this.setItem(key, existingContent);
    }

    removeObject(key: string, objectKey: string) {
        let existingContent = JSON.parse(this.getItem(key));
        delete existingContent[objectKey];
        this.setItem(key, existingContent);
    }

    getObjectItem(key, objectKey) {
        let existingContent = JSON.parse(this.getItem(key));
        if (!existingContent) {
            return null;
        }
        return existingContent[objectKey];
    }

    public getChannelId(): string {
        return this.get('channelId');
    }

    public setChannelId(chid: string): void {
        this.set('channelId', chid);
    }

    public getAgoraToken(): string {
        return this.get('agoraToken');
    }

    public setAgoraToken(chid: string): void {
        this.set('agoraToken', chid);
    }

    //getUserId
    public getUserId(): string {
        return this.get('userId');
    }

    public setUserId(data: string): void {
        this.set('userId', data);
    }

    //getChannelUserId
    public getChannelUserId(): string {
        return this.get('userWSid');
    }

    public setChannelUserId(data: string): void {
        this.set('userWSid', data);
    }

    //getSocketSessionId
    public getSocketSessionId(): string {
        return this.get('socketSessionId');
    }

    public setSocketSessionId(data: string): void {
        this.set('socketSessionId', data);
    }

    //getSocketSessionId
    public getContractorId(): string {
        return this.get('contractorId');
    }

    public setContractorId(data: string): void {
        this.set('contractorId', data);
    }

    //getSocketSessionId
    public getVendor(): string {
        return this.get('vendor');
    }

    public setVendor(data: string): void {
        this.set('vendor', data);
    }

    public clearVendor(): void {
        sessionStorage.removeItem('vendor');
    }

    //userType
    public getUserType(): string {
        return this.get('userType');
    }

    public setUserType(data: string): void {
        this.set('userType', data);
    }

    public getAccessToken() {
        return this.get('accessToken');
    }

    public setAccessToken(token: string) {
        this.set('accessToken', token);
    }

    public removeAccessToken() {
        sessionStorage.removeItem('accessToken');
    }

    //userType
    public getGUID(): string {
        return this.get('guid-token');
    }

    public setGUID(data: string): void {
        this.set('guid-token', data);
    }

    public clearFullStorage(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

    public clearBasicStorage(): void {
        this.clearVendor();
    }

    //////////////////////////////
    //////////////////////////////
    private get(name: string): any {
        const data: any = sessionStorage.getItem(name);
        try {
            return JSON.parse(data);
        } catch (e) {
            return data;
        }
    }

    private set(name: string, data) {
        sessionStorage.setItem(name, JSON.stringify(data));
    }
}
