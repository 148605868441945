import { Customer } from './../../_model/common/customer.model';
import { Slot } from './../../_model/reservations/slot.model';
import { DaySlot } from './../../_model/reservations/day-slot.model';
import { ResSelectSlotResult } from './../../_model/reservations/res-select-slot-result.model';
import { ReservationInfo } from './../../_model/reservations/reservation-info.model';
export class BaseStateModel {
    reservationInfo: ReservationInfo;
    resParams: ResSelectSlotResult;
    slots: DaySlot[];
    selectedSlot: Slot;
    customer: Customer;
}
