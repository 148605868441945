import { GuidInfo } from './../../../_model/common/guid-info.model';
import { Component, Injectable, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
    public version: string = environment.VERSION;

    @Input()
    public guidData: GuidInfo;

    constructor() {}

    ngOnInit() {}
}
