import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicComponent } from './modules/public/public.component';

const routes: Routes = [
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    { path: 'public/telemedicine', redirectTo: 'public/eposvet' },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'eposvet',
                loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
            },
            {
                path: 'eposvet',
                children: [
                    {
                        path: 'pravila-obdelave',
                        loadChildren: () =>
                            import('./modules/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
                    },
                    {
                        path: 'doctor',
                        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
                    },
                ],
            },
            {
                path: 'eposvet/invite',
                loadChildren: () =>
                    import('./modules/telemedicine-invite/telemedicine-invite.module').then(
                        (m) => m.TelemedicineInviteModule,
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
