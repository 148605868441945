import { Customer } from './../../_model/common/customer.model';
import { Service } from './../../_model/common/service.model';
import { Slot } from './../../_model/reservations/slot.model';
import { DaySlot } from './../../_model/reservations/day-slot.model';
import { Injectable } from '@angular/core';
import { ResSelectSlotRequest } from './../../_model/reservations/res-select-slot-request.model';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ReservationsService } from './../../_services/reservations.service';
import { ResSelectSlotResult } from './../../_model/reservations/res-select-slot-result.model';
import { ReservationInfo } from './../../_model/reservations/reservation-info.model';
import { BaseStateModel } from './base-state.model';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { StateToken, State, Store, Action, StateContext, Selector } from '@ngxs/store';
import { BaseStateActions } from './base.actions';
import * as moment from 'moment';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    reservationInfo: undefined,
    resParams: undefined,
    slots: undefined,
    selectedSlot: undefined,
    customer: undefined,
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);

    constructor(private reservationsService: ReservationsService) {}

    @Selector([BASESTATE_TOKEN])
    public static getReservationInfo(state: BaseStateModel): ReservationInfo {
        return state.reservationInfo;
    }

    @Selector([BASESTATE_TOKEN])
    public static getSelectedSlot(state: BaseStateModel): Slot {
        return state.selectedSlot;
    }

    @Selector([BASESTATE_TOKEN])
    public static getCustomer(state: BaseStateModel): Customer {
        return state.customer;
    }

    @Selector([BASESTATE_TOKEN])
    public static getReservationParams(state: BaseStateModel): ResSelectSlotResult {
        return state.resParams;
    }

    @Selector([BASESTATE_TOKEN])
    public static getSlots(state: BaseStateModel): DaySlot[] {
        return state.slots;
    }

    @Selector([BASESTATE_TOKEN])
    public static getAllReservationData(state: BaseStateModel): any {
        return { reservationInfo: state.reservationInfo, slots: state.slots, resParams: state.resParams };
    }

    @Action(BaseStateActions.ClearBaseState)
    public ClearState(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.ClearBaseState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(BaseStateActions.Reservations.SetReservationInfo)
    public SetReservationInfo(
        ctx: StateContext<BaseStateModel>,
        { reservationInfo }: BaseStateActions.Reservations.SetReservationInfo,
    ) {
        ctx.patchState({ reservationInfo: reservationInfo });
    }

    @Action(BaseStateActions.Reservations.SetReservationParams)
    public SetReservationParams(
        ctx: StateContext<BaseStateModel>,
        { resParams }: BaseStateActions.Reservations.SetReservationParams,
    ) {
        ctx.patchState({ resParams: resParams });
    }

    @Action(BaseStateActions.Reservations.SetReservationToken)
    public SetReservationToken(
        ctx: StateContext<BaseStateModel>,
        { token }: BaseStateActions.Reservations.SetReservationToken,
    ) {
        ctx.setState(
            patch<BaseStateModel>({
                resParams: patch({
                    guid: token,
                }),
            }),
        );

        // ctx.patchState(
        //     patch<BaseStateModel>({
        //         resParams: patch<ResSelectSlotResult>({
        //             guid: token,
        //         }),
        //     }),
        // );

        // ctx.setState(
        //     patch<BaseStateModel>({ resParams: patch<ResSelectSlotResult>({ guid: token }) }),
        // );
    }

    @Action(BaseStateActions.Reservations.UpdateReservationInfo)
    public UpdateReservationInfo(
        ctx: StateContext<BaseStateModel>,
        { name, value }: BaseStateActions.Reservations.UpdateReservationInfo,
    ) {
        ctx.setState(
            patch<BaseStateModel>({
                reservationInfo: patch({ [name]: (val) => (val = value) }),
            }),
        );
    }

    @Action(BaseStateActions.Reservations.GetSlotsData)
    public GetReservationSlotsData(
        ctx: StateContext<BaseStateModel>,
        { guid }: BaseStateActions.Reservations.GetSlotsData,
    ) {
        return this.reservationsService.getTokenInfo(guid).pipe(
            mergeMap((reservationInfo: ReservationInfo) => {
                let startDate = moment(reservationInfo.startDate);
                if (startDate < moment()) {
                    startDate = moment();
                }

                return this.reservationsService
                    .getFreeSlots(
                        guid,
                        reservationInfo.maskedContractorId,
                        reservationInfo.contractor.subcontractors[0].id,
                        reservationInfo.service.id,
                        startDate,
                        startDate.clone().add(14, 'days'),
                    )
                    .pipe(
                        tap((slots: Slot[]) => {
                            // const parsedSlots: DaySlot[] = this.manageSlots(slots)

                            // const data = new ResSelectSlotResult().deserialize({
                            //     guid: guid,
                            // });
                            ctx.setState(
                                patch<BaseStateModel>({
                                    resParams: new ResSelectSlotResult().deserialize({
                                        guid: guid,
                                    }),
                                    reservationInfo: reservationInfo,
                                    slots: this.manageSlots(slots),
                                }),
                            );

                            // ctx.patchState({
                            //     resParams: data,
                            //     reservationInfo: reservationInfo,
                            //     slots: parsedSlots,
                            // });

                            // return parsedSlots;
                        }),
                    );
            }),
        );

        // .subscribe(
        //     (reservationInfo: any) => {
        //         debugger;
        //         // reservationInfo.guid = guid;
        //         // debugger;
        //         // this.store.dispatch(new BaseStateActions.Reservations.SetReservationInfo(reservationInfo));

        //         // this.reservationInfo = response;
        //         // this.clearError();

        //         // let startDate = moment(reservationInfo.startDate);
        //         // if (startDate < moment()) {
        //         //     startDate = moment();
        //         // }

        //         // .subscribe((slotsResponse) => {
        //         //     this.request = new ResSelectSlotRequest().deserialize({ dayslots: slotsResponse });
        //         //     this.request.reservationInfo = reservationInfo;
        //         // });
        //     },
        //     (error: any) => {
        //         debugger;
        //         console.log(error);
        //         // if (error?.error?.errors[0]?.msg === 'Token already used') {
        //         //     // this.setError('Povezava je že uporabljena', 'Termin ste že izbrali.');
        //         // } else {
        //         //     // this.setError('Napaka', error.error.errors[0].msg);
        //         // }
        //     },
        // );
        // ctx.setState(
        //     patch<BaseStateModel>({
        //         reservationInfo: patch({ [name]: (val) => (val = value) }),
        //     }),
        // );
    }

    private manageSlots(slots): DaySlot[] {
        const parsedSlots: DaySlot[] = [];

        if (slots.length === 0) {
            return parsedSlots;
        }

        // this.minWeekDate = moment(slots[0].start).endOf('isoWeek');
        // this.maxWeekDate = moment(slots[slots.length - 1].start).startOf('isoWeek');
        slots.forEach((slot) => {
            const slotStart = moment(slot.start);
            const dateTomorrow = moment().add(1, 'days');
            const laterThan24Hours = slotStart > dateTomorrow;
            if (laterThan24Hours) {
                const day: moment.Moment = moment(slot.start); //.format('YYYY-MM-DD');

                let index = parsedSlots.findIndex((item: any) => {
                    return item.date.isSame(day, 'date');
                });

                if (index === -1) {
                    parsedSlots.push(
                        new DaySlot().deserialize({
                            date: day,
                            slots: [],
                        }),
                    );
                    index = parsedSlots.findIndex((item: any) => {
                        return item.date.isSame(day, 'date');
                    });
                }

                parsedSlots[index].slots.push(slot);
            }
        });

        return parsedSlots;
    }

    @Action(BaseStateActions.Widget.GetSlotsData)
    public GetWidgetSlotsData(ctx: StateContext<BaseStateModel>, { from, to }: BaseStateActions.Widget.GetSlotsData) {
        const params: ResSelectSlotResult = ctx.getState().resParams;

        return this.reservationsService.getContractorServiceInfo(params.apiKey, params.serviceKey).pipe(
            mergeMap((service: Service) => {
                const resinfo: ReservationInfo = new ReservationInfo().deserialize({
                    service: service,
                });
                return (
                    this.reservationsService
                        // const params: ResSelectSlotResult = ctx.getState().resParams;
                        .getFreeSlotsWidget(params.apiKey, params.subcKey, params.serviceKey, from, to)
                        .pipe(
                            map((slots: Slot[]) => {
                                ctx.setState(
                                    patch<BaseStateModel>({
                                        reservationInfo: resinfo,
                                        slots: this.manageSlots(slots),
                                    }),
                                );
                            }),
                        )
                );
            }),
        );
    }

    @Action(BaseStateActions.Widget.SetSelectedSlot)
    public SetSelectedSlot(ctx: StateContext<BaseStateModel>, { slot }: BaseStateActions.Widget.SetSelectedSlot) {
        ctx.setState(
            patch<BaseStateModel>({
                selectedSlot: slot,
                reservationInfo: patch({
                    startDate: slot.start,
                }),
            }),
        );
    }

    @Action(BaseStateActions.Widget.SetCustomer)
    public SetCustomer(ctx: StateContext<BaseStateModel>, { customer }: BaseStateActions.Widget.SetCustomer) {
        ctx.setState(
            patch<BaseStateModel>({
                customer: customer,
            }),
        );
    }
}
