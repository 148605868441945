import { ReservationsService } from './_services/reservations.service';
import { BaseState } from './_state/base/base.state';
import { environment } from './../environments/environment';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import localeSlExtra from '@angular/common/locales/extra/sl';
import { StorageService } from './_services/storage.service';
import { PublicComponent } from './modules/public/public.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { FooterComponent } from './theme/layouts/footer/footer.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';
import { NgxAgoraModule } from 'ngx-agora';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

registerLocaleData(localeSl, 'sl', localeSlExtra);
const bugsnagClient = Bugsnag.start({
    apiKey: 'a37354cad215403fc8920726e0d8de52',
    releaseStage: environment.RELEASE_STAGE,
    appVersion: environment.VERSION,
});
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(bugsnagClient);
}

@NgModule({
    declarations: [AppComponent, ThemeComponent, PublicComponent],
    imports: [
        NgbModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        NgxsModule.forRoot([BaseState], {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
        RecaptchaModule,
        RecaptchaV3Module,
    ],
    providers: [
        ScriptLoaderService,
        StorageService,
        { provide: LOCALE_ID, useValue: 'sl' },
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'sl',
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
