import {WsCallStateType} from './../../../_enums/ws-call-state-type.enum';
import {WebsocketClientService} from './../../../_services/websocket-client.service';
import {TelemedicineService} from './../../../_services/telemedicine.service';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {interval, Observable, Subscription, timer} from 'rxjs';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {RxWebsocketSubject} from '../../../_services/rxWebsocketSubject';
import {CaseService} from '../../../_services/case.service';
import {LocalAvalaibleEnum} from '../../../_enums/localAvalaible.enum';
import {TelemedicineAgoraService} from '../../../_services/telemedicine.agora.service';
import {FullScreenService} from '../../../_services/full-screen.service';
import {WebSocketChannelUser} from '../../../_model/telemedicine/webSocketChannelUser.model';

@Component({
    selector: 'telemedicine-video',
    templateUrl: './telemedicine-video.component.html',
    styleUrls: ['./telemedicine-video.component.scss'],
})
export class TelemedicineVideoComponent implements OnInit, OnDestroy {
    @Input() set setData(value) {
        if (value) {
            this.data = value;
            this.checkIfCallCanStart();
            // this.callStartDateTime = moment(this.data.startDate).subtract(15, 'minutes').format('DD.MM.YYYY, HH:mm:ss');
            // this.callEndDateTime = moment(this.data.endDate).add(1, 'hours').format('DD.MM.YYYY, HH:mm:ss');
            // this.currentDateTime = moment().format('DD.MM.YYYY, HH:mm:ss');
            // this.is15minBefore = this.callStartDateTime < this.currentDateTime;
            // this.isMax1hourAfter = this.callEndDateTime > this.currentDateTime;
            // if (this.is15minBefore && this.isMax1hourAfter) {
            //     this.enableTelemedicine = true;
            // } else {
            //     this.enableTelemedicine = false;
            // }
        }
    }

    @Input() userType: string;
    @Input() guid: string;

    @Output() privacyPolicyToggle: EventEmitter<any> = new EventEmitter();

    @ViewChild('remoteVideo', {read: ViewContainerRef, static: true})
    remoteVideo: ViewContainerRef;

    settingsToggled: boolean = false;
    fullscreenToggled: boolean = this.fs.isFullScreen();

    uid: any;
    userId: string;
    connected = false;
    ready = false;

    resized = false;
    callStartDateTime: moment.Moment;
    callEndDateTime: any;
    currentDateTime: any;
    is15minBefore: any;
    isMax1hourAfter: any;
    checkedPrivacyPolicy: string;
    enableTelemedicine: boolean = false;
    outsideCallInProgress: boolean = false;

    lostConnection: any = false;

    readPrivacyPolicy: boolean = false;
    data: any = [];
    privacyPolicyUrl: string;
    basePrivacyPolicyUrl: string;
    currentDate = new Date();

    public audioVolume = 100;

    public callState: WsCallStateType = WsCallStateType.NONE; //0 - Joined, 1 - Joined, not published, 2 - Published, in call, 3 - unpublished, leaving, 4 - ended
    public callStates = WsCallStateType;

    public localAvailable: { initial: LocalAvalaibleEnum, final: LocalAvalaibleEnum } = {
        initial: null,
        final: LocalAvalaibleEnum.WAITING
    };
    public localAvalaibleEnum = LocalAvalaibleEnum;

    public audioDevices: MediaDeviceInfo[] = [];
    public videoDevices: MediaDeviceInfo[] = [];
    public playoutDevices: MediaDeviceInfo[] = [];

    public activeVideoDevice: MediaStreamTrack;
    public activeAudioDevice: MediaStreamTrack;
    public activePlayoutDevice: MediaDeviceInfo;

    public channelData: WebSocketChannelUser;
    public remoteClient: WebSocketChannelUser;

    /**/
    private activeWS: RxWebsocketSubject<any>;
    private callState$: Subscription;

    constructor(
        //private agoraService: NgxAgoraService,
        private route: ActivatedRoute,
        private fs: FullScreenService,
        private http: HttpClient,
        private _elementRef: ElementRef,
        private telemedRest: TelemedicineService,
        private wsClient: WebsocketClientService,
        private telemedAgoraService: TelemedicineAgoraService
    ) {
        window.onbeforeunload = (e) => {
            this.wsClient.closeSocket(this.guid);
        };
    }

    ngOnInit() {
        this.checkPrivacyPolicy();
        if (this.readPrivacyPolicy || this.checkedPrivacyPolicy === '1') {
            this.callState$ = this.wsClient.getCallState().subscribe((callState) => {
                if (this.callState !== callState && callState === WsCallStateType.NONE) {
                    // this.checkAndInitChannel();
                }
                this.callState = callState;
            });

            this.checkIfCallCanStart();
            this.checkCallSettings();
        }

        this.wsClient.getChannelData(this.guid).subscribe((channelData) => {
            this.channelData = channelData;
        })

        this.wsClient.getRemoteClient(this.guid).subscribe((remoteClient) => {
            if (remoteClient) {
                this.remoteClient = remoteClient;
                if (remoteClient.clientReady && this.callState === WsCallStateType.CALL_STARTING) {
                    this.telemedAgoraService.join(remoteClient);
                }
            }
        });

        this.wsClient.getOutsideCallInProgress().subscribe((inProgress) => {
            this.outsideCallInProgress = inProgress;
        })

        this.telemedAgoraService.getLocalAvalaible().subscribe((res) => {
            this.localAvailable = res;
        });

        this.telemedAgoraService.getCameras().subscribe((devices) => {
            this.videoDevices = devices;
        });

        this.telemedAgoraService.getRecordingDevices().subscribe((devices) => {
            this.audioDevices = devices;
        });

        this.telemedAgoraService.getPlayoutDevices().subscribe((devices) => {
            this.playoutDevices = devices;
        });

        this.telemedAgoraService.getActiveDevices().subscribe((activeDevices) => {
            this.activeVideoDevice = activeDevices.video;
            this.activeAudioDevice = activeDevices.audio;
            this.activePlayoutDevice = activeDevices.playout;
        });
    }

    ngOnDestroy(): void {
        this.wsClient.closeSocket(this.guid);
    }

    /*
    private getChannelData(): WebSocketChannelUser {
        return JSON.parse(sessionStorage.getItem('channelData'));
    }

    private setChannelData(channelData: WebSocketChannelUser): void {
        this.channelData = channelData;
        sessionStorage.setItem('channelData', JSON.stringify(channelData));
    }*/

    private checkIfCallCanStart(): void {
        this.callStartDateTime = moment(
            this.data.reservationFrom ? this.data.reservationFrom : this.data.startDate,
        ).subtract(15, 'minutes');
        this.callEndDateTime = moment(this.data.reservationTo ? this.data.reservationTo : this.data.endDate).add(
            1,
            'hours',
        );
        this.currentDateTime = moment();
        this.is15minBefore = this.callStartDateTime.isBefore(this.currentDateTime);
        this.isMax1hourAfter = this.callEndDateTime.isAfter(this.currentDateTime);
        if (this.is15minBefore && this.isMax1hourAfter) {
            this.enableTelemedicine = true;
        } else {
            this.enableTelemedicine = false;
            //dodamo 10 sekund za rezervo
            const timeToWait: number = this.callStartDateTime.diff(moment(), 'milliseconds') + 10000;
            timer(timeToWait).subscribe(() => {
                this.checkIfCallCanStart();
            });
        }
    }

    public startCall() {
        this.setCallState(WsCallStateType.CALL_STARTING);
        this.wsClient.sendSocketMessage(this.guid, {
            clientReady: true,
        });
        this.wsClient.checkRemotes(this.guid);
        //this.wsClient.checkPublishedDoctors();
        //this.wsClient.checkPublishedPatients();
    }

    public endCall(): void {
        this.resized = false;
        this.toggleModalSize();
        if (this.settingsToggled) {
            this.toggleSettings();
        }
        this.wsClient.endCall(this.guid);
        this.telemedAgoraService.leave();
    }

    protected init(): void {
        this.setCallState(WsCallStateType.CALL_STARTING);
        //
    }

    public toggleAudio(forceTo?: boolean) {
        this.telemedAgoraService.toggleAudio(forceTo);
    }

    public toggleVideo(forceTo?: boolean) {
        this.telemedAgoraService.toggleVideo(forceTo);
    }

    public toggleFullScreen(element) {
        this.fs.toggleFullScreen(element);
        this.fullscreenToggled = !this.fullscreenToggled;
    }

    public toggleModalSize(forceTo?: string) {
        this.fs.turnOffFullScreen();
    }

    public switchCamera(camera: any) {
        this.telemedAgoraService.switchCamera(camera);
    }

    public switchAudio(audio: any) {
        this.telemedAgoraService.switchAudio(audio);
    }

    public switchPlayoutDevice(device: MediaDeviceInfo) {
        this.telemedAgoraService.switchPlayoutDevice(device);
    }

    public toggleAudioVolume() {
        if (this.telemedAgoraService.audioVolume === 0) {
            this.setAudioVolume(100);
        } else {
            this.setAudioVolume(0);
        }
    }

    public setCallState(state: WsCallStateType) {
        this.wsClient.setCallState(state);
    }

    public checkCallSettings() {
        this.setCallState(WsCallStateType.DEVICE_TEST);
        this.telemedAgoraService.initLocalMedia(this.uid, null, this.guid);
        this.wsClient.checkRemotes(this.guid);
    }

    public toggleSettings() {
        this.settingsToggled = !this.settingsToggled;
    }

    public checkPrivacyPolicy() {
        const cid = sessionStorage.getItem('contractorId');
        const vid = sessionStorage.getItem('vendor');
        const bsId = sessionStorage.getItem('bs');
        
        this.checkedPrivacyPolicy = sessionStorage.getItem('pp');
        if (this.checkedPrivacyPolicy === '1') {
            this.readPrivacyPolicy = true;
        } else {
            this.basePrivacyPolicyUrl = '/public/eposvet/pravila-obdelave?cid=';
            if (this.userType === 'Patient') {
                if (cid && bsId === '1') {
                    this.privacyPolicyUrl = 'https://www.medifit.si/dokumenti/splosni-pogoji-storitve-eposvet-izvajalca-zdravstvenih-storitev-medifit-pe-medifit-medical/?cid='  + JSON.parse(cid);
                }
                else if (cid && !vid) {
                    this.privacyPolicyUrl = this.basePrivacyPolicyUrl + JSON.parse(cid);
                } else if (cid && vid) {
                    this.privacyPolicyUrl = this.basePrivacyPolicyUrl + JSON.parse(cid) + '&vid=' + JSON.parse(vid);
                } else {
                    this.privacyPolicyUrl = this.basePrivacyPolicyUrl + '5ORvlBVKRPGppAMOoKtqme4'; // Medifit Contractor ID
                }
            } else {
                this.privacyPolicyUrl = 'https://www.medifit.si/dokumenti/politika-zasebnosti-druzbe-medifit/';
            }
        }
    }

    public togglePrivacyPolicy() {
        this.readPrivacyPolicy = !this.readPrivacyPolicy;
        if (this.readPrivacyPolicy) {
            sessionStorage.setItem('pp', '1');
        }
    }
    public continue() {
        this.checkedPrivacyPolicy = '1';
        this.privacyPolicyToggle.emit(true);
        this.ngOnInit();
    }
    public setAudioVolume(setTo: number = null) {
        this.telemedAgoraService.setAudioVolume(setTo);
    }
}
