<div>
    <div class="mf-view-tools" (click)="activeModal.dismiss('close-no-error'); (false)">
        <div class="d-flex justify-content-center">
            XXXXXXXXXX->{{ isVideo }}
            {{ documentType }}
            <a
                *ngIf="navigationButtons"
                placement="top"
                ngbTooltip="Prejšnji"
                href="#"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="activeModal.close('previous'); (false)"
            >
                <i class="la la-arrow-left"></i>
            </a>
            <a
                *ngIf="!isVideo"
                openDelay="100"
                placement="top"
                ngbTooltip="Natisni dokument"
                href="#"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="printFile(); $event.stopPropagation(); (false)"
            >
                <i class="la la-print"></i>
            </a>
            <a
                placement="top"
                ngbTooltip="Prenesi dokument"
                href="#"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="downloadFile(); $event.stopPropagation(); (false)"
            >
                <i class="la la-download"></i>
            </a>

            <a
                placement="top"
                *ngIf="!isVideo"
                href="#"
                ngbTooltip="Povečaj"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="zoom_in(); $event.stopPropagation(); (false)"
            >
                <i class="la la-plus"></i>
            </a>
            <a
                placement="top"
                *ngIf="!isVideo"
                href="#"
                ngbTooltip="Pomanjšaj"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="zoom_out(); $event.stopPropagation(); (false)"
            >
                <i class="la la-minus"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' && !fullScreenEnabled"
                placement="top"
                href="#"
                ngbTooltip="Razširi"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="fullscreen(); $event.stopPropagation(); (false)"
            >
                <i class="la la-expand"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' && fullScreenEnabled"
                placement="top"
                href="#"
                ngbTooltip="Skrči"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="fullscreen(); $event.stopPropagation(); (false)"
            >
                <i class="la la-compress"></i>
            </a>
            <a
                placement="top"
                ngbTooltip="Zapri"
                href="#"
                class="btn btn-danger m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="activeModal.dismiss('close-no-error'); (false)"
            >
                <i class="la la-close"></i>
            </a>
            <a
                placement="top"
                *ngIf="navigationButtons"
                ngbTooltip="Naslednji"
                href="#"
                class="btn btn-primary m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                (click)="activeModal.close('next'); (false)"
            >
                <i class="la la-arrow-right"></i>
            </a>
        </div>
    </div>
    <iframe #blob frameborder="0" width="0px" height="0px" style="left: -9999px;"></iframe>

    <div
        id="pdf"
        *ngIf="documentType === 'application/pdf'"
        style="overflow: auto;"
        class="test"
        (click)="clickEvent($event)"
    >
        <!-- <div
            class="mf-digital-signature-seal"
            *ngIf="!checkIfEmpty(signature)"
        >
            <div class="d-flex">
                <div>
                    <i
                        class="la la-shield pt-1"
                        style="height: 30px;"
                    ></i>
                </div>
                <div>
                    <div>
                        <span>Podpisal: </span> <span class="m--font-boldest">{{ signature?.signer }}</span>
                    </div>
                    <div>
                        <span>Datum: </span> <span class="m--font-boldest">{{ signature?.date }}</span>
                    </div>
                    <div>
                        <span>IZS: </span> <span class="m--font-boldest">{{ signature?.contractor }}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <pdf-viewer
            [src]="documentFile"
            [fit-to-page]="true"
            [original-size]="false"
            style="display: block;"
            (after-load-complete)="stopLoading()"
            [zoom]="zoom"
        ></pdf-viewer>
    </div>
    <div class="mf-image-view" (click)="clickEvent($event)">
        <img id="img" [src]="documentFile | safe: 'url'" class="center" [ngStyle]="{ width: widthPic }" />
    </div>

    <div *ngIf="documentType == 'text/plain'" class="mf-text-plain-view p-3 m-1" (click)="clickEvent($event)">
        <p>
            {{ documentFile }}
        </p>
    </div>
    <!-- <video
        #videoPlayer
        controls
        preload="auto"
        class="center"
        [ngClass]="{ hideVideo: !documentType.includes('video') }"
    >
        <source src="documentFile | safe: 'url'" type="video/mp4" />
        Vaš brskalnik ne podpira ogleda videa
    </video> -->
</div>
