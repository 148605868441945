import { StorageService } from './storage.service';
import { Customer } from './../_model/common/customer.model';
import { HttpClientService } from './http-client.service';
import { GuidInfo } from './../_model/common/guid-info.model';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private storage: StorageService,
    ) {}

    // public getAppVendorInfo(token: string): Observable<any> {
    //     return this.httpClient.get(this.helper.getPatientUrl() + `/access/reservations/reservations/${token}`);
    // }

    public getGuidInfo(token: string, isDoctor: boolean = false): Observable<GuidInfo> {
        let url: string = '';
        if (isDoctor) {
            url = this.helper.getReservationsUlr(`reservations/${token}?isDoctor=true`);
        } else {
            url = this.helper.getReservationsUlr(`reservations/${token}`);
        }
        return this.httpClient.get(url);
    }

    public addCustomer(maskedContractorId: string, customer: Customer): Observable<string> {
        return this.httpClient.post(
            this.helper.getReservationsUlr(`contractors/${maskedContractorId}/customers`),
            customer,
            {
                responseType: 'text',
            },
        );
    }

    ///booking/patient/api/upload/token/{token}
    public uploadFile(file: File, comment?: string): Observable<any> {
        const guid: string = this.storage.getChannelId();
        return this.httpClient.uploadFile(this.helper.getPatientUrl() + `upload/token/${guid}`, file, comment);
    }

    public getDocument(documentId: string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getDocumentUrl() + `contractors/0/documents/${documentId}`);
    }

    public getSurveyPaperPreviewReservation(prereservationId: string, paperid: number, signature: boolean = false): Observable<any> {
        let url: string = `/reservations/${prereservationId}/surveys/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }
    public getSurveyPaperPreviewReservationMasked(prereservationId: string, paperid: string, signature: boolean = false): Observable<any> {
        let url: string = `/reservations/${prereservationId}/masked/surveys/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }
    public printWhiteReferral(paperid: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + `/white-referral/${paperid}`);
    }

}
