import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HttpClientService {
    constructor(private httpClient: HttpClient, private storage: StorageService) {}

    public post(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.post(url, data, httpOptions);
    }

    public put(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.put(url, data, httpOptions);
    }

    public delete(url: string, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.delete(url, httpOptions);
    }

    public get(url: string, options?: any): Observable<any> {
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.get(url, httpOptions);
    }

    public uploadFile(url: string, file: File, comment?: string): Observable<HttpEvent<any>> {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);
        let params = new HttpParams();

        let options = {
            params: params,
            reportProgress: true,
            responseType: 'text' as 'text',
            headers: new HttpHeaders({ authorization: `Bearer ${this.storage.getAccessToken()}` }),
        };

        // options.headers.append('content-length', '13110529');

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClient.request(req);
    }

    public getAttachment(url: string): Observable<HttpEvent<any>> {
        // let data = this.storage.getToken();
        let options = {
            responseType: 'blob',
            observe: 'response',
            headers: new HttpHeaders({ authorization: `Bearer ${this.storage.getAccessToken()}` }),
        };
        const req = new HttpRequest('GET', url, options);
        return this.httpClient.request(req);
    }
}
