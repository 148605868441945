import { Injectable } from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CaseService {
    public observer = new ReplaySubject(1);

    emitData(data) {
        this.observer.next(data);
    }
}