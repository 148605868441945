import { DocumentSign } from './../_model/common/document-sign.model';
import { MediaViewPopupComponent } from './../_components/common/media-view-popup/media-view-popup.component';
import { Observable, from } from 'rxjs';
import { OpenDocumentRequest } from './../_model/common/open-document-request.model';
import { VendorType } from './../_enums/vendorType.enum';
import { Title } from '@angular/platform-browser';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Business } from '../_model/business.model';
import { TelemedicineVideoComponent } from '../modules/telemedicine/telemedicine-video/telemedicine-video.component';
import { GeneraliVideoComponentComponent } from '../modules/telemedicine/telemedicine-video/generali-video-component/generali-video-component.component';
import * as _ from 'lodash';
import { StorageService } from './storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor(private titleService: Title, private storage: StorageService, private modalService: NgbModal) {}

    // public openDocumentViewerSimple(data: OpenDocumentRequest, filename: string, print?: boolean): Observable<any> {
    //     return this.openDocumentViewer(data.document, data.document.type, filename, print, data.signature);
    // }

    public openDocumentViewer(data: OpenDocumentRequest, filename: string, print?: boolean): Observable<any> {
        const type: string = data.document.type;
        const signature: DocumentSign = data.signature;
        const navigationButtons: boolean = false;
        const supportedFileTypes: string[] = [
            'application/pdf',
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/bmp',
            'image/gif',
            'application/msword',
            'text/plain',
            // 'application/dicom',
        ];
        if (supportedFileTypes.includes(type)) {
            const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
                size: 'lg',
                windowClass: 'mf-transparent-popup',
            });
            modalMedidaView.componentInstance.setdocumentFile = data.document;
            modalMedidaView.componentInstance.fileName = filename;
            modalMedidaView.componentInstance.documentType = type;
            modalMedidaView.componentInstance.signature = signature;
            modalMedidaView.componentInstance.navigationButtons = navigationButtons;
            if (print) {
                modalMedidaView.componentInstance.printFile();
            }

            return from(modalMedidaView.result);
        } else {
            console.log('Neznan format dokumenta, prenašam!');
            FileSaver.saveAs(data.document, filename);
        }
    }

    public isDoctor(): boolean {
        return this.storage.getUserType() == 'Doctor';
    }
    public getPatientUrl(path?: string): string {
        //http://localhost:9090/booking/patient/api

        if (!path) {
            path = '';
        }
        return (
            environment.patient.protocol +
            environment.patient.rootUrl +
            environment.patient.port +
            environment.patient.url +
            '/' +
            path
        );
    }

    public getReservationsUlr(path?: string) {
        if (!path) {
            path = '';
        }
        return (
            environment.service.protocol +
            environment.service.rootUrl +
            environment.service.apiPort +
            environment.service.apiUrl +
            '/' +
            path
        );
    }

    public getWebSocketTelemedUrl(): string {
        return (
            environment.webSocketTelemed.protocol +
            environment.webSocketTelemed.rootUrl +
            environment.webSocketTelemed.port +
            environment.webSocketTelemed.url
        );
    }

    getTelemedUrl(): string {
        //http://localhost:9090/booking/patient/api
        return (
            environment.telemed.protocol +
            environment.telemed.rootUrl +
            environment.telemed.port +
            environment.telemed.url
        );
    }

    getEPosvetUrl(): string {
        //http://localhost:9090/booking/patient/api
        return (
            environment.ePosvet.protocol +
            environment.ePosvet.rootUrl +
            environment.ePosvet.port +
            environment.ePosvet.url
        );
    }

    public getDocumentUrl(path?: string): string {
        //http://localhost:9090/booking/patient/api

        if (!path) {
            path = '';
        }
        return (
            environment.document.protocol +
            environment.document.rootUrl +
            environment.document.port +
            environment.document.url +
            '/' +
            path
        );
    }

    getPrintServerUrl(): string {
        //http://localhost:9090/booking/papi
        return (
            environment.printserver.protocol +
            environment.printserver.rootUrl +
            environment.printserver.port +
            environment.printserver.url
        );
    }

    public getBusiness(bs: any): Business {
        let tmp = bs.id;
        if (tmp === undefined) {
            tmp = bs;
        }
        switch (tmp) {
            case 1:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Generali',
                    iconPath: '../../../assets/app/media/img/bu/generali.svg',
                    component: GeneraliVideoComponentComponent,
                });
            default:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: bs.name ? `${bs.name}` : `eAmbulanta`,
                    iconPath: '../../../assets/app/media/img/bu/eambulanta.svg',
                    component: TelemedicineVideoComponent,
                });
        }
    }

    public checkStructError(data: any, code: string): boolean {
        const err = _.get(data, 'error.errors[0]', undefined);
        if (err) {
            if (err.code === code) {
                return true;
            }
        }
        return false;
    }

    public setTitleVendor(vendor: string): void {
        let vendorName: string = 'Medifit | ePosvet';
        switch (vendor) {
            case VendorType.INFONET:
                vendorName = 'SRC Infonet | VideoPosvet';
                break;
            case VendorType.VIZIJA:
                vendorName = 'Nova Vizija | ePosvet';
                break;
            case VendorType.LIST:
                vendorName = 'List | video posvet';
                break;
        }
        this.titleService.setTitle(vendorName);
    }
}
