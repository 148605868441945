import { Slot } from './slot.model';
import { ReservationInfo } from './reservation-info.model';
import { DaySlot } from './day-slot.model';
import { Serializable } from '../deserialize.interface';

export class ResSelectSlotResult implements Serializable<ResSelectSlotResult> {
    guid: string;
    aggregateId: number;
    prereservationId: number;
    selectedSlot: Slot;
    apiKey: string;
    subcKey: string;
    serviceKey: string;

    deserialize(input) {
        this.guid = input.guid;
        this.aggregateId = input.aggregateId;
        this.prereservationId = input.prereservationId;
        this.selectedSlot = input.selectedSlot;

        this.apiKey = input.apiKey;
        this.subcKey = input.subcKey;
        this.serviceKey = input.serviceKey;

        return this;
    }
}
