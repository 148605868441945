import { StorageService } from './../_services/storage.service';
import { VendorType } from '../_enums/vendorType.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tmAppName',
})
export class TelemedicineVendorAppNamePipe implements PipeTransform {
    constructor(private storage: StorageService) {}
    transform(value: any, args?: any): any {
        const vendor: string = this.storage.getVendor();
        switch (vendor) {
            case VendorType.INFONET:
                return 'VideoPosvet';
            case VendorType.LIST:
                return 'video posvet';
            case VendorType.VIZIJA:
                return 'eVideoPosvet';
            default:
                return value;
        }
    }
}
